import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keyBy } from 'lodash-es';
import { map, Observable } from 'rxjs';

@Injectable()
export class ZendeskResTransformInterceptor implements HttpInterceptor {
  private readonly zendeskRedirectEndpoint = '/zendesk/redirect/api/v2';

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let secureReq = request.clone();

    if (request.url.includes(this.zendeskRedirectEndpoint) && request.method === 'GET') {
      secureReq = request.clone({
        setParams: {
          per_page: request.params.get('page_size') || '100',
        },
      });
    }

    return next.handle(secureReq).pipe(
      map((event) => {
        if (
          event instanceof HttpResponse &&
          request.url.includes(this.zendeskRedirectEndpoint) &&
          event.body
        )
          return event.clone({
            body: {
              ...event.body,
              results:
                event.body.results ||
                event.body.sections ||
                event.body.articles ||
                event.body.requests ||
                this.getCommentsWithUser(event.body.comments, event.body.users),
            },
          });
        return event;
      }),
    );
  }

  private getCommentsWithUser(comments: any[], users: any[]) {
    if (!comments) return [];

    const usersHash = keyBy(users, 'id');
    return comments.map((comment) => ({
      ...comment,
      user: usersHash[comment.author_id],
    }));
  }
}
