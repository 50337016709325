import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { inflect } from '@locumsnest/core/src/lib/helpers/inflector';

import { BaseRequestTransformInterceptor } from './base-request-transform.interceptor';

@Injectable()
export class HttpApiJsonInflectionInterceptor
  extends BaseRequestTransformInterceptor
  implements HttpInterceptor
{
  protected transformRequest(body) {
    return this.reverseInflect(body);
  }

  protected transformResponse(body) {
    return this.inflect(body);
  }

  private inflect(body) {
    return inflect(body, 'camelCase');
  }
  private reverseInflect(body) {
    return inflect(body, 'snakeCase');
  }
}
