import { animate, group, style, transition, trigger } from '@angular/animations';

export const slideAnimation = (name: string, direction: 'X' | 'Y') =>
  trigger(name, [
    transition(':enter', [
      style({ transform: `translate${direction}(100%)`, opacity: 0 }),
      group([
        animate('0.3s 0.1s ease', style({ transform: `translate${direction}(0)` })),
        animate('0.3s ease', style({ opacity: 1 })),
      ]),
    ]),
    transition(':leave', [
      group([
        animate('0.3s ease', style({ transform: `translate${direction}(100%)` })),
        animate('0.3s 0.2s ease', style({ opacity: 0 })),
      ]),
    ]),
  ]);
