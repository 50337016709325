import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PRODUCT_CODES } from 'apps/hospital-admin/src/app/core/constants';
import { get, keyBy, trim } from 'lodash-es';
import { distinctUntilChanged, filter, first, map, Observable, tap } from 'rxjs';

import { IMicroAppConfiguration, IMicroAppListItemState, NavItem } from './interfaces';
import {
  selectMicroAppAccessRequestUrl,
  selectMicroAppBasePath,
  selectMicroAppCode,
  selectMicroAppConfiguration,
  selectMicroAppDisplayName,
  selectMicroAppNavigation,
  selectMicroAppPathPrefix,
} from './micro-app.reducer';
import { SetConfigurationSignal } from './micro-app.signals';
import { MICRO_APP_CONFIGURATION } from './opaque-tokens';

@Injectable({
  providedIn: 'root',
})
export class MicroAppService {
  private readonly microAppConfiguration: {
    [key: string]: IMicroAppConfiguration;
  };
  private readonly microAppConfigurationList: IMicroAppConfiguration[];
  private readonly defaultMicroAppConfiguration: IMicroAppConfiguration;
  constructor(
    private store: Store,
    @Inject(MICRO_APP_CONFIGURATION) microAppConfiguration: IMicroAppConfiguration[],
  ) {
    this.microAppConfigurationList = microAppConfiguration;
    this.microAppConfiguration = keyBy(microAppConfiguration, 'code');
    this.defaultMicroAppConfiguration = microAppConfiguration[0];
  }

  getMicroAppByCode(code: string) {
    return get(this.microAppConfiguration, code);
  }

  public getMicroAppFromPath(
    currentConfig: IMicroAppConfiguration,
    path: string,
  ): IMicroAppConfiguration {
    const pathParam = trim(path, '/').split('/')[0];
    return get(
      this.microAppConfiguration,
      pathParam,
      (pathParam?.includes('profile') || pathParam?.includes('logout')) && currentConfig?.code
        ? currentConfig
        : this.defaultMicroAppConfiguration,
    );
  }

  public getMicroAppConfiguration(): Observable<IMicroAppConfiguration> {
    return this.store.pipe(select(selectMicroAppConfiguration));
  }

  public getMicroAppNavigation(): Observable<NavItem[]> {
    return this.store.pipe(select(selectMicroAppNavigation));
  }

  public getMicroAppBasePath(): Observable<string> {
    return this.store.pipe(select(selectMicroAppBasePath));
  }

  public getMicroAppPathPrefix(): Observable<string> {
    return this.store.pipe(select(selectMicroAppPathPrefix));
  }

  public getAppPath(path: string): Observable<string> {
    return this.getMicroAppPathPrefix().pipe(map((pathPrefix) => `${pathPrefix}/${path}`));
  }

  public getMicroAppAccessRequestUrl(): Observable<string> {
    return this.store.pipe(select(selectMicroAppAccessRequestUrl));
  }

  public getMicroAppDisplayName(): Observable<string> {
    return this.store.pipe(select(selectMicroAppDisplayName));
  }

  public getMicroAppCode(): Observable<string> {
    return this.store.pipe(select(selectMicroAppCode)).pipe(filter((code) => !!code));
  }
  public getMicroAppActive(code: string) {
    return this.getMicroAppCode().pipe(
      map((activeAppCode: string) => activeAppCode === code),
      distinctUntilChanged(),
    );
  }
  public setMicroAppConfiguration(path: string): Observable<IMicroAppConfiguration> {
    return this.getMicroAppConfiguration().pipe(
      first(),
      tap((currentConfig) => {
        const microApp = this.getMicroAppFromPath(currentConfig, path);
        if (microApp.code !== currentConfig.code) {
          this.store.dispatch(new SetConfigurationSignal({ configuration: microApp }));
        }
      }),
    );
  }

  public getMicroAppListState(): Observable<IMicroAppListItemState[]> {
    return this.getMicroAppConfiguration().pipe(
      map((app) =>
        this.microAppConfigurationList.map((microApp) => ({
          ...microApp,
          isActive: microApp.code === app.code,
        })),
      ),
    );
  }

  public isAgencyPlatform(): Observable<boolean> {
    return this.getMicroAppCode().pipe(map((code) => code === PRODUCT_CODES.AGENCY));
  }
}
