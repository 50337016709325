import { MicroAppMessages, SetConfigurationMessage } from './micro-app.messages';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMicroAppState, IMicroAppConfiguration } from './interfaces';

export const featureKey = 'microApp';

const defaultMicroAppState: IMicroAppState = {
  configuration: {
    code: '',
    display: '',
    basePath: '',
    pathPrefix: '',
    navItems: [],
    accessRequestUrl: '',
  },
};

export function reducer(state: IMicroAppState = defaultMicroAppState, action: MicroAppMessages) {
  switch (action.type) {
    case SetConfigurationMessage.TYPE:
      state = { ...state, configuration: action.payload.configuration };
      break;
  }
  return state;
}

export const selectMicroAppState = createFeatureSelector<IMicroAppState>(featureKey);

export const selectMicroAppConfiguration = createSelector(
  selectMicroAppState,
  (microAppState: IMicroAppState) => microAppState.configuration
);

export const selectMicroAppNavigation = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.navItems
);

export const selectMicroAppBasePath = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.basePath
);

export const selectMicroAppPathPrefix = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.pathPrefix
);

export const selectMicroAppAccessRequestUrl = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.accessRequestUrl
);

export const selectMicroAppDisplayName = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.display
);

export const selectMicroAppCode = createSelector(
  selectMicroAppConfiguration,
  (microAppConfiguration: IMicroAppConfiguration) => microAppConfiguration.code
);
