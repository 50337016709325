import { DateRange, extendMoment } from 'moment-range';
import momentTS from 'moment-timezone';

import { DATE_FORMAT, DATE_FORMAT_DB, DATE_TIME_SEC_FORMAT, TIME_FORMAT } from '../types/constants';
import { IDateFormPeriod, IFormPeriod, IPeriod } from './interfaces';
import { Time } from './time';

const moment = extendMoment(momentTS);

export class DateTime {
  private constructor() {
    throw new Error('Static class should not get instantiated');
  }

  static getDateFromPartialISOString(date: string) {
    return (date || '').split('T').shift();
  }

  static getTimeFromPartialISOString(date: string) {
    return (date || '').split('T').pop().split('.').shift();
  }

  static concatDateTime(date: Date, time: string) {
    const dateString = moment(date).format(DATE_FORMAT_DB);
    return moment(dateString + ' ' + time, DATE_TIME_SEC_FORMAT);
  }

  static forPeriod({ startDate, startTime, endDate, endTime }: IPeriod) {
    return {
      start: this.concatDateTime(startDate, startTime),
      end: this.concatDateTime(endDate, endTime),
    };
  }

  static forFormPeriod({ startDate, startTime, endDate, endTime }: IFormPeriod) {
    return {
      start: Time.getMomentDateTime(startDate, startTime),
      end: Time.getMomentDateTime(endDate, endTime),
    };
  }

  static rangeForFormPeriod(period: IFormPeriod): DateRange {
    const { start, end } = DateTime.forFormPeriod(period);
    return moment.range(start, end);
  }

  static rangeForStartEnd(start: moment.MomentInput, end: moment.MomentInput): DateRange {
    return moment.range(moment(start), moment(end));
  }

  static rangeForDateFormPeriod(period: IDateFormPeriod): DateRange {
    return moment.range(moment(period.startDate), moment(period.endDate));
  }

  static rangeForDateFormStartEndTime(
    startTime: string | number,
    endTime: string | number,
  ): DateRange {
    return moment.range(moment(startTime), moment(endTime));
  }

  static rangeForPeriod(period: IPeriod): DateRange {
    const { start, end } = DateTime.forPeriod(period);
    return moment.range(start, end);
  }

  static roundedRangeForPeriod(period: IPeriod): DateRange {
    const r = DateTime.forPeriod(period);
    const { start } = r;
    const end = r.end <= r.start ? r.end.add(24, 'hours') : r.end;
    return moment.range(Time.roundToClosestMinute(start), Time.roundToClosestMinute(end));
  }

  static dateTimeFromString(startDate: string, startTime: string) {
    return moment(startDate + ' ' + startTime, DATE_FORMAT + ' ' + TIME_FORMAT);
  }

  static intersectionExistsForDuration(
    startTime: string,
    duration: number,
    fromTime: Date,
    toTime: Date,
  ) {
    const start = moment(startTime);
    const end = moment(startTime).add(duration, 'minutes');
    const range1 = moment.range(start, end);

    const from = moment(fromTime);
    const to = moment(toTime);
    const range2 = moment.range(from, to);
    return range1.overlaps(range2);
  }

  static getDurationMs(input?: momentTS.DurationInputArg1, unit?: momentTS.DurationInputArg2) {
    return moment.duration(input, unit).asMilliseconds();
  }

  static overlaps(
    startDate: Date | number | string,
    endDate: Date | number | string,
    fromTime: Date | number | string,
    toTime: Date | number | string,
  ): boolean {
    const range1 = moment.range(moment(startDate), moment(endDate));
    const range2 = moment.range(moment(fromTime), moment(toTime));
    return range1.overlaps(range2);
  }

  static range(start: number, end: number): DateRange {
    return moment.range(moment(start), moment(end));
  }
}
