import { ofType } from '@ngrx/effects';

import { InvalidActionTypeError } from '@locumsnest/core/src/lib/ngrx/errors';
import { ActionNamespace, ActionType, isActionType } from '@locumsnest/core/src/lib/ngrx/types';

import { Action } from '../action';

export const getActionType = <F extends string, A extends ActionNamespace, N extends string>(
  featureNamespace: F,
  actionNameSpace: A,
  actionName: N,
): ActionType<F, A, N> => {
  const actionType = `[${featureNamespace}] [${actionNameSpace}] ${actionName}`;
  if (isActionType(actionType)) {
    return actionType as ActionType<F, A, N>;
  }
  throw new InvalidActionTypeError(actionType);
};
export function getFeatureMessageType<F extends string>(featureNamespace: F) {
  return <N extends string>(actionName: N) =>
    getActionType<F, ActionNamespace.MESSAGE, N>(
      featureNamespace,
      ActionNamespace.MESSAGE,
      actionName,
    );
}
export function getFeatureSignalType<F extends string>(featureNamespace: F) {
  return <N extends string>(actionName: N) =>
    getActionType<F, ActionNamespace.SIGNAL, N>(
      featureNamespace,
      ActionNamespace.SIGNAL,
      actionName,
    );
}

export function ofSignalType<
  F extends string,
  N extends string,
  T extends typeof Action<ActionType<F, ActionNamespace.SIGNAL, N>, unknown>,
>(action: T) {
  return ofType<InstanceType<T>>(action.TYPE);
}

export function ofMessageType<
  F extends string,
  N extends string,
  T extends typeof Action<ActionType<F, ActionNamespace.MESSAGE, N>, unknown>,
>(action: T) {
  return ofType<InstanceType<T>>(action.TYPE);
}
// Here Goes the magic:
// const a = getActionType<'featureNamespace',ActionNamespace.MESSAGE, 'actionName'>(
//   'featureNamespace',ActionNamespace.MESSAGE, 'actionName'
// )
// const b = getActionType<'featureNamespace',ActionNamespace.MESSAGE, 'does something'>(
//   'featureNamespace',ActionNamespace.MESSAGE, 'does something'
// )
// a===b
