import { Action } from '@ngrx/store';
import { NgrxFormControlId } from 'ngrx-forms';

const NAME_SPACE = 'core/ngrx/forms/';
const MARK_MULTIPLE_AS_DIRTY = `${NAME_SPACE}MARK_MULTIPLE_AS_DIRTY`;

export class MarkMultipleAsDirtyAction implements Action {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static readonly TYPE = MARK_MULTIPLE_AS_DIRTY;
  readonly type = MARK_MULTIPLE_AS_DIRTY;
  constructor(public payload: { controlIds: NgrxFormControlId[] }) {}
}
