import {
  createEntityAdapter as createNgrxEntityAdapter,
  EntityState,
  EntityAdapter as NativeEntityAdapter,
} from '@ngrx/entity';
import { get, isArray, isNil, set } from 'lodash-es';

import { IQueryParams } from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { Id } from '../interfaces';
import { IEntityAdapterOptions, IIndexField } from './interfaces';

export type IndexKey = Id | Record<string, Id>;
export interface IUpdateMetaData {
  params: IQueryParams;
}
export interface EntityAdapter<T> extends NativeEntityAdapter<T> {
  upsertMany<S extends EntityState<T>>(updates: T[], state: S, metaData?: IUpdateMetaData): S;
}
const DEFAULT_METADATA = {
  params: {},
};
const isEntityChanged = (entity, state) => {
  const lastUpdate = get(state, ['entities', entity.id, 'lastUpdate']);
  return isNil(lastUpdate) || +lastUpdate !== +entity.lastUpdate;
};
const setIndex = (state, indexVal, idVal) => {
  if (state) {
    return { ...state, [indexVal]: idVal };
  }
  return { [indexVal]: idVal };
};
export const buildIndexValue = (field: IIndexField, entity) => {
  if (!field.indexFn) {
    return entity[field.fieldName];
  }
  return field.indexFn(entity);
};

export const getIndexValue = (field: IIndexField, indexKey: IndexKey) => {
  if (!field.indexFn) {
    return indexKey as Id;
  }
  return field.indexFn(indexKey);
};

const getChangedEntities = (entities, state) =>
  entities.filter((entity) => isEntityChanged(entity, state));

const setUniqueIndex = (state, entities: any[], field: IIndexField, idKey) => {
  for (const entity of entities) {
    const indexVal = buildIndexValue(field, entity);
    state.indexes[field.fieldName] = setIndex(
      state.indexes[field.fieldName],
      indexVal,
      entity[idKey]
    );
  }
};

const initializeParamIndex = (indexes, field, fieldParam) => {
  if (isNil(fieldParam)) {
    return;
  }
  if (isArray(fieldParam)) {
    for (const val of fieldParam) {
      indexes[field.fieldName] = setIndex(indexes[field.fieldName], val, new Set());
    }
    return;
  }
  indexes[field.fieldName] = setIndex(indexes[field.fieldName], fieldParam, new Set());
};

const setMultiIndex = (state, entities, field: IIndexField, idKey) => {
  const updatedIndexes = {};
  for (const entity of entities) {
    const key = entity[idKey];
    const indexVal = buildIndexValue(field, entity);
    let currentIndexSet =
      updatedIndexes[indexVal] ?? get(state.indexes, [field.fieldName, indexVal]);
    // for new index collections
    if (!currentIndexSet) {
      updatedIndexes[indexVal] = new Set();
      currentIndexSet = updatedIndexes[indexVal];
    }
    // for index collections(including new)
    if (!currentIndexSet.has(key)) {
      if (!updatedIndexes[indexVal]) {
        updatedIndexes[indexVal] = new Set(currentIndexSet);
      }
      updatedIndexes[indexVal].add(key);
    }
    if (updatedIndexes[indexVal]) {
      state.indexes[field.fieldName] = setIndex(
        state.indexes[field.fieldName],
        indexVal,
        updatedIndexes[indexVal]
      );
    }
  }
};
const deleteIndex = (indexVal: Id, indexes) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [indexVal]: _, ...newIndexes } = indexes;
  return newIndexes;
};

const deleteMultiIndex = (state, key, field: IIndexField) => {
  const entity = state.entities[key];
  const indexVal = buildIndexValue(field, entity);
  const newIndex = new Set(get(state.indexes, [field.fieldName, indexVal]));
  newIndex.delete(entity.id);
  if (newIndex.size === 0) {
    state.indexes[field.fieldName] = deleteIndex(indexVal, state.indexes[field.fieldName]);
    return state;
  }
  state.indexes[field.fieldName] = setIndex(state.indexes[field.fieldName], indexVal, newIndex);
  return state;
};

const deleteUniqueIndex = (state, key, field: IIndexField) => {
  const entity = state.entities[key];
  const indexVal = buildIndexValue(field, entity);
  state.indexes[field.fieldName] = deleteIndex(indexVal, state.indexes[field.fieldName]);
  return state;
};

/**
 * Merges two sets using copy on change strategy
 *
 * @param {Set<any>} set1
 * @param {Set<any>} set2
 * @return {Set<any>}
 */
const mergeSet = (set1: Set<any>, set2: Set<any>) => {
  if (!set1 || set1.size === 0) {
    return set2;
  }
  if (!set2 || set2.size === 0) {
    return set1;
  }
  return new Set([...set1, ...set2]);
};

const mergeSetDict = (dict1: Record<string, Set<any>>, dict2: Record<string, Set<any>>) => {
  const newDict = { ...dict1 };
  for (const key in newDict) {
    if (dict2[key]) {
      newDict[key] = mergeSet(newDict[key], dict2[key]);
    }
  }
  return {
    ...dict2,
    ...newDict,
  };
};
export const index = (fields: IIndexField[], idKey = 'id') => ({
  initializeParamIndex: (state, params) => {
    const indexes = {};
    for (const field of fields) {
      if (!field.unique) {
        initializeParamIndex(
          indexes,
          field,
          params[field.fieldName] || params[`${field.fieldName}Id`]
        );
      }
    }
    const newIndexKeys = Object.keys(indexes);
    if (newIndexKeys.length) {
      const newIndexState = { ...state.indexes };
      for (const indexKey of newIndexKeys) {
        if (newIndexState[indexKey]) {
          newIndexState[indexKey] = mergeSetDict(newIndexState[indexKey], indexes[indexKey]);
        } else {
          newIndexState[indexKey] = indexes[indexKey];
        }
      }
      return {
        ...state,
        indexes: newIndexState,
      };
    }
    return state;
  },
  createMultiple: (entities, state) => {
    state.indexes = { ...state.indexes };
    for (const field of fields) {
      if (field.reAssignMent) {
        set(state.indexes, field.fieldName, {});
      }
      if (field.unique) {
        setUniqueIndex(state, entities, field, idKey);
      } else {
        setMultiIndex(state, entities, field, idKey);
      }
    }
    return state;
  },
  deleteOne: (key: Id, state) => {
    for (const field of fields) {
      if (field.unique) {
        deleteUniqueIndex(state, key, field);
      } else {
        deleteMultiIndex(state, key, field);
      }
    }
    return state;
  },
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noop = (_, state, __?) => state;
const noopParamIndex = (state, __?) => state;
const noopIndex = { initializeParamIndex: noopParamIndex, createMultiple: noop, deleteOne: noop };
export const cacheAdapter = <T>(adapter: NativeEntityAdapter<T>, indexInstance = noopIndex) => {
  const upsertOne = adapter.upsertOne;
  const upsertMany = adapter.upsertMany;
  const removeOne = adapter.removeOne;
  const removeMany = adapter.removeMany;
  const setAll = adapter.setAll;
  const setOne = adapter.setOne;
  const addMany = adapter.addMany;

  adapter.setAll = <S extends EntityState<T>>(entities: T[], state: S) => {
    state = setAll(entities, state);
    state['indexes'] = {};
    return indexInstance.createMultiple(entities, state);
  };
  adapter.addOne = <S extends EntityState<T>>(entity: T, state: S) => {
    state = setOne(entity, state);
    return indexInstance.createMultiple([entity], state);
  };
  adapter.upsertOne = <S extends EntityState<T>>(entity: T, state: S) => {
    if (isEntityChanged(entity, state)) {
      state = upsertOne(entity, state);
      return indexInstance.createMultiple([entity], state);
    }
    return state;
  };
  adapter.upsertMany = <S extends EntityState<T>>(
    entities: T[],
    state: S,
    metaData = DEFAULT_METADATA
  ) => {
    const changedEntities = getChangedEntities(entities, state);
    const { params } = metaData;
    state = indexInstance.initializeParamIndex(state, params);
    if (changedEntities.length) {
      state = upsertMany(changedEntities, state);
      return indexInstance.createMultiple(changedEntities, state);
    }
    return state;
  };
  adapter.addMany = <S extends EntityState<T>>(entities: T[], state: S) => {
    const changedEntities = getChangedEntities(entities, state);
    if (changedEntities.length) {
      state = addMany(changedEntities, state);
      return indexInstance.createMultiple(changedEntities, state);
    }
    return state;
  };
  adapter.removeOne = <S extends EntityState<T>>(key, state: S) => {
    state = {
      ...state,
      indexes: { ...state['indexes'] },
    };
    state = indexInstance.deleteOne(key, state);
    return removeOne(key, state);
  };
  adapter.removeMany = <S extends EntityState<T>>(keys, state: S) => {
    state = {
      ...state,
      indexes: { ...state['indexes'] },
    };
    for (const key of keys) {
      state = indexInstance.deleteOne(key, state);
    }
    return removeMany(keys, state);
  };

  return adapter;
};

export const createEntityAdapter = <T>(options: IEntityAdapterOptions<T>, indexFn = noopIndex) =>
  cacheAdapter(createNgrxEntityAdapter(options), indexFn);
export { EntityState } from '@ngrx/entity';
