import { EntityState } from '@ngrx/entity';
import { Action, MemoizedSelector, select, Store } from '@ngrx/store';
import { merge, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

import { RequestOptions } from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { IPaginatedResponse, IQueryParams } from '../..';
import {
  IClearPaginationMessageConstructor,
  IUpsertMultipleMessageConstructor,
  IUpsertPageMessage,
  IUpsertPageMessageConstructor,
} from '../adapters/paginated-state-adapter';
import {
  IClearSubresourcePaginationMessage,
  IClearSubresourcePaginationPayload,
  IUpsertSubresourcePagePayload,
  ResourceIdType,
} from '../adapters/paginated-subresource-state-adapter';
import { ISubresourcePaginationSelectors } from './../adapters/paginated-subresource-state-adapter/interfaces';
import { StateService } from './state-service';

/**
 *
 *
 * @export
 * @abstract
 * @class PaginatedSubresourceStateService
 * @extends {StateService<T, S>}
 * @template T entity
 * @template U upsert message type
 * @template C clear message type
 * @template P request options type
 */

export abstract class PaginatedSubresourceStateService<
  T,
  U extends IUpsertPageMessage<T, string>,
  C extends IClearSubresourcePaginationMessage<string>,
  M extends Action,
  O = RequestOptions<any, { [key: string]: any }>,
  D = T
> extends StateService<T, O, D> {
  abstract get upsertMessageClass(): IUpsertPageMessageConstructor<
    U,
    T,
    IUpsertSubresourcePagePayload<T>
  >;
  abstract get clearMessageClass(): IClearPaginationMessageConstructor<
    C,
    IClearSubresourcePaginationPayload
  >;
  abstract get paginationSelectors(): ISubresourcePaginationSelectors<T>;
  abstract get entityStateSelector(): MemoizedSelector<object, EntityState<T>>;
  abstract get upsertMultipleMessage(): IUpsertMultipleMessageConstructor<M, T>;
  protected abstract store: Store;

  getPage(page: number) {
    // to be implemented
  }

  getConsecutivePageEntities(namespace: string, resourceId: ResourceIdType) {
    return this.store.pipe(
      select(this.paginationSelectors.selectConsecutivePageEntities(namespace, resourceId))
    );
  }

  getConsecutivePageNumbers(namespace, resourceId) {
    return this.store.pipe(
      select(this.paginationSelectors.selectConsecutivePageNumbers(namespace, resourceId))
    );
  }

  loadAllPages(
    namespace: string,
    resourceId: ResourceIdType,
    requestOptions: O,
    filters: IQueryParams = {}
  ) {
    return merge(
      of(this.getClearPaginationMessage(resourceId)),
      this.loadPage(namespace, resourceId, requestOptions, 1, filters, true)
    );
  }

  loadNext(
    namespace: string,
    resourceId: ResourceIdType,
    requestOptions: O,
    filters: IQueryParams = {}
  ) {
    return this.store.pipe(
      select(this.paginationSelectors.selectConsecutivePageNumbers(namespace, resourceId)),
      first(),
      mergeMap((pages) => {
        pages.sort((a, b) => a - b);
        return this.loadPage(
          namespace,
          resourceId,
          requestOptions,
          pages[pages.length - 1] + 1,
          filters
        );
      })
    );
  }

  initializePagination(
    namespace: string,
    resourceId: ResourceIdType,
    requestOptions: O,
    filters: IQueryParams = {}
  ) {
    return merge(
      // clear pagination
      of(this.getClearPaginationMessage(resourceId)),
      // load 1st page
      this.loadPage(namespace, resourceId, requestOptions, 1, filters)
    );
  }

  refreshLoadedPages<Z extends IPaginatedResponse<any>>(
    namespace: string,
    resourceId: ResourceIdType,
    requestOptions: O,
    filters: IQueryParams = {}
  ) {
    return this.getConsecutivePageNumbers(namespace, resourceId).pipe(
      first(),
      mergeMap((pages) => pages),
      mergeMap((page) => this.loadPage(namespace, resourceId, requestOptions, page, filters, false))
    );
  }

  loadPage(
    namespace: string,
    resourceId: ResourceIdType,
    requestOptions: O,
    page: number,
    filters: IQueryParams = {},
    recursiveLoadNext = false
  ): Observable<Action> {
    return this.persistenceService.retrieve({ page, ...filters }, requestOptions).pipe(
      mergeMap((result) => {
        const actions: Observable<Action>[] = [
          of(this.getUpsertPageMessage(result, resourceId, namespace, page)),
          of(this.getUpsertMultipleMessage(result.results)),
        ];
        if (recursiveLoadNext && result.next) {
          actions.push(
            this.loadPage(
              namespace,
              resourceId,
              requestOptions,
              page + 1,
              filters,
              recursiveLoadNext
            )
          );
        }
        return actions;
      }),
      mergeMap((x) => x)
    );
  }

  areAllLoaded(namespace: string, resourceId: ResourceIdType) {
    return this.store.pipe(
      select(this.paginationSelectors.selectResourcePagination(namespace, resourceId)),
      map((pagination) => {
        if (!pagination) return false;
        let count = 0;

        for (const page in pagination.pages) {
          if (pagination.pages.hasOwnProperty(page)) {
            count += pagination.pages[page].length;
          }
        }

        return pagination.totalCount === count;
      })
    );
  }

  private getUpsertPageMessage(
    response: IPaginatedResponse<T>,
    resourceId: ResourceIdType,
    namespace: string,
    pageNumber: number
  ): U {
    const { count, results, next, previous } = response;
    return new this.upsertMessageClass({
      count,
      results,
      next,
      previous,
      pageNumber,
      resourceId,
      namespace,
    });
  }
  private getClearPaginationMessage(resourceId: ResourceIdType): C {
    return new this.clearMessageClass({ resourceId });
  }

  private getUpsertMultipleMessage(entities: T[]): M {
    return new this.upsertMultipleMessage({ entities });
  }
}
