import { intersection, isArray, isEmpty, isNil, isNumber, isString, mergeWith } from 'lodash-es';
import { maxLength, minLength } from 'ngrx-forms/validation';

import { IQueryParams } from '../..';
import { Time } from './time';

export const intersectNonEmpty = <T>(c1: ArrayLike<T>, c2: ArrayLike<T>): ArrayLike<T> => {
  if (!isEmpty(c1) && !isEmpty(c2)) {
    return intersection(c1, c2);
  }
  if (!isEmpty(c1)) {
    return c1;
  }
  if (!isEmpty(c2)) {
    return c2;
  }
  return [];
};

export const extractPath = (url: string) => url.split('?')[0];

export const unquoteSecureCookieValue = (str) => str.replace(/"/g, '');

export const compareProperties = <T>(
  objA: T,
  objB: T,
  properties: Array<{ name: keyof T; ascending: boolean }>,
) => {
  properties = [...properties];

  const property = properties.shift();

  if (objA === objB) {
    compareProperties(objA, objB, properties);
  }

  if (property.ascending) {
    return objA[property.name] > objB[property.name] ? 1 : -1;
  } else {
    return objA[property.name] > objB[property.name] ? -1 : 1;
  }
};

export const getParentUrl = (url: string) => {
  const urlParts = url.split('/');
  urlParts.pop();
  return urlParts.join('/');
};

export const formatQueryParam = (key, value) => `${key}=${encodeURIComponent(value)}`;

export const buildQueryParams = (queryParams: IQueryParams) => {
  const params = [];
  for (const key of Object.keys(queryParams)) {
    const value = queryParams[key];
    if (isArray(value)) {
      value.forEach((v) => params.push(formatQueryParam(key, v)));
    } else {
      params.push(formatQueryParam(key, value));
    }
  }
  return '?' + params.join('&');
};
export const getEntityFromFormState = (entity) => ({
  ...entity,
  id: isNumber(entity.id) ? entity.id : undefined,
});

export const getEntityListFromFormState = (entityList) => entityList.map(getEntityFromFormState);

export const formatNamespaceWithId = (namespace: string, id?: number) => `${namespace}-${id || ''}`;

function getDescendingSortField(columnName: string) {
  return columnName
    .split(',')
    .map((column) => '-' + column)
    .join(',');
}

export const updateSortedField = (sortedField: string, fieldToSort: string) => {
  const descendingFieldToSort = getDescendingSortField(fieldToSort);
  return sortedField === descendingFieldToSort ? fieldToSort : descendingFieldToSort;
};

export const applyServerDateFormat = (formDate: string) =>
  isString(formDate) && !isEmpty(formDate) ? Time.formatDbDateFromDatePicker(formDate) : null;

export const getMultiResourceId = <T extends string | number = number>(
  resourceType: string,
  id: T,
) => `${resourceType}:${id}`;

export const concatMerge = (object: any, ...otherArgs: any[]) =>
  mergeWith(object, ...otherArgs, (objValue, srcValue) => {
    if (isArray(objValue) && isArray(srcValue)) {
      return [...objValue, ...srcValue];
    }
  });

export const isEmptyFilterValue = (v) => isNil(v) || (isArray(v) && !v.length);

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const minCharLength = (length) => (value) => minLength(length)([...value]);

export const maxCharLength = (length) => (value) => maxLength(length)([...value]);

export const formatMoney = (value: string | number | null | undefined) =>
  isNumeric(value) ? (+value).toFixed(2) : (value as string | null | undefined);
