import { isString } from 'lodash-es';
import moment from 'moment-timezone';

import {
  DATE_TIME_SERVER_ACCEPT_FORMAT,
  DATE_TIME_SERVER_PARSE_FORMATS,
} from '@locumsnest/core/src/lib/types/constants';

export const isDateString = (str: string): str is DateString => {
  if (isString(str)) {
    return moment(str.split('+')[0], DATE_TIME_SERVER_PARSE_FORMATS, true).isValid();
  }
  return false;
};
/**
 * Converts to DateString from Date
 * @param date
 */
// export const getDateString= (date: Date): DateString =>moment(
//   date
// ).format(DATE_TIME_SERVER_FORMAT) as DateString;
/**
 * Converts to Date from DateString
 * @param date
 */
export const getDateFromDateString = (dateString: DateString): Date =>
  moment(dateString.split('+')[0], DATE_TIME_SERVER_PARSE_FORMATS).toDate();

export const getNaiveDateString = (date: Date): string =>
  moment(+date).format(DATE_TIME_SERVER_ACCEPT_FORMAT);

export type DateString = string;

export const isTimeString = (str: string): str is TimeString =>
  str.match(/^(([0-1][0-9])|([2][0-3])):([0-5][0-9]):([0-5][0-9])$/) !== null;

enum TimeStringType {}
export type TimeString = string & TimeStringType;
