import {
  createCloneDocumentToHospitalFormStateReducer,
  DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE,
} from './clone-document-to-hospital-form-state';
import { ICloneDocumentToHospitalFormState } from './interfaces';

export * from './interfaces';
export const createCloneDocumentToHospitalFormStateAdapter = (formID) => ({
  createCloneDocumentToHospitalFormStateReducer(
    initialFormState: ICloneDocumentToHospitalFormState = DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE
  ) {
    return createCloneDocumentToHospitalFormStateReducer(formID, initialFormState);
  },
});
