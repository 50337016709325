import { INameSpacedPaginationState } from './../../interfaces/pagination';
import { getPaginationInitialState } from './../../helpers/pagination';
import { SignalableFactory, MessageableFactory } from '../../ngrx';
import { Action } from '@ngrx/store';
import { EntityAdapter } from '@ngrx/entity';
import { Pages, IPaginationState } from '../../interfaces/pagination';
import { ISubresourceUpsertPagePayload, ISubresourcePaginationState } from '.';
import { ILoadingStateAdapter } from '../loading-state-adapter/interfaces';

export function createPaginatedSubresourceStateFactory<F extends string, T>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>,
  adapter: EntityAdapter<T>,
  loadingStateAdapter: ILoadingStateAdapter,
  getId = (entity) => entity.id
) {
  class UpsertPageMessage extends messageableFactory.create<
    'Upsert Pages',
    ISubresourceUpsertPagePayload<T>
  >('Upsert Pages') {}
  class ResetPaginationSignal extends signalableFactory.create<'Reset Pagination Signal', {}>(
    'Reset Pagination Signal'
  ) {}
  class ResetPaginationMessage extends messageableFactory.create<'Reset Pagination Message', {}>(
    'Reset Pagination Message'
  ) {}
  type PaginationMessages =
    | UpsertPageMessage
    | ResetPaginationMessage
    | (Action & { payload: any });
  const DEFAULT_INITIAL_STATE: ISubresourcePaginationState = {};

  function pageSizeReducer(state: number = null, action: PaginationMessages) {
    switch (action.type) {
      case UpsertPageMessage.TYPE:
        const payload = action.payload as ISubresourceUpsertPagePayload<T>;

        if (!payload.next && payload.pageNumber > 1) {
          return (payload.count - payload.results.length) / payload.pageNumber - 1;
        }

        return payload.results.length;
    }

    return state;
  }
  function pagesReducer(state: Pages, action: PaginationMessages): Pages {
    switch (action.type) {
      case UpsertPageMessage.TYPE: {
        const payload = action.payload as ISubresourceUpsertPagePayload<T>;
        return {
          [(payload as ISubresourceUpsertPagePayload<T>).pageNumber]: payload.results.map((x) =>
            getId(x)
          ),
          ...state,
        };
      }
    }
    return state;
  }

  function subresourceReducer(
    state: IPaginationState = getPaginationInitialState(),
    action: PaginationMessages
  ): IPaginationState {
    switch (action.type) {
      case UpsertPageMessage.TYPE: {
        const _action = action as UpsertPageMessage;
        //const payload = action.payload as ISubresourceUpsertPagePayload<T>;

        return {
          ...state,
          pageSize: pageSizeReducer(state.pageSize, _action),
          currentPage: _action.payload.pageNumber,
          totalCount: _action.payload.count,
          pages: pagesReducer(state.pages, _action),
        };
      }
    }

    return state;
  }

  function nameSpacedSubresourceReducer(
    state: INameSpacedPaginationState = {},
    action: PaginationMessages
  ): INameSpacedPaginationState {
    switch (action.type) {
      case UpsertPageMessage.TYPE: {
        const payload = action.payload as ISubresourceUpsertPagePayload<T>;
        const namespace = payload.namespace;
        return {
          ...state,
          [namespace]: subresourceReducer(state[namespace], action),
        };
      }
    }
    return state;
  }

  function createReducer(initialState: ISubresourcePaginationState = DEFAULT_INITIAL_STATE) {
    const paginationStateReducer = (
      state: ISubresourcePaginationState = initialState,
      action: PaginationMessages
    ): ISubresourcePaginationState => {
      switch (action.type) {
        case UpsertPageMessage.TYPE: {
          const payload = action.payload as ISubresourceUpsertPagePayload<T>;
          const resourceId = payload.resourceId;

          return {
            ...state,
            [resourceId]: nameSpacedSubresourceReducer(state[resourceId], action),
          };
        }
        case ResetPaginationMessage.TYPE:
          return DEFAULT_INITIAL_STATE;
      }
      return state;
    };
    return paginationStateReducer;
  }
  function getMessages() {
    return {
      UpsertPageMessage,
      ResetPaginationMessage,
    };
  }
  function getSignals() {
    return {
      ResetPaginationSignal,
    };
  }

  return {
    createReducer,
    getMessages,
    getSignals,
  };
}
