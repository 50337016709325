import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromMicroApp from './micro-app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MicroAppEffects } from './micro-app.effect';
import { IMicroAppConfiguration } from './interfaces';
import { MICRO_APP_CONFIGURATION } from './opaque-tokens';

@NgModule({
  imports: [
    StoreModule.forFeature(fromMicroApp.featureKey, fromMicroApp.reducer),
    EffectsModule.forFeature([MicroAppEffects]),
  ],
})
export class MicroAppModule {
  static forRoot<T>(
    microAppConfiguration: IMicroAppConfiguration[]
  ): ModuleWithProviders<MicroAppModule> {
    return {
      ngModule: MicroAppModule,
      providers: [
        {
          provide: MICRO_APP_CONFIGURATION,
          useValue: microAppConfiguration,
        },
      ],
    };
  }
}
