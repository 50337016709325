import { inject } from '@angular/core';
import { EntityState } from '@ngrx/entity';
import { Action, MemoizedSelector, select, Store } from '@ngrx/store';
import { AppState } from 'apps/hospital-admin/src/app/reducers';
import { flatMap, get, isNil } from 'lodash-es';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  filter,
  first,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import {
  IQueryParams,
  JsonRequestOptions,
  RequestOptions,
} from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { IPaginatedResponse } from '../..';
import {
  getMultiPageEntities,
  getMultiPageEntitiesCount,
  getPageEntities,
  IClearPaginationMessage,
  IUpsertPageMessage,
} from '../adapters/paginated-state-adapter';
import { isEmptyFilterValue } from '../helpers/util';
import { MicroAppService } from '../micro-app/micro-app.service';
import {
  IPaginationSelectors,
  IUpsertMultipleMessageConstructor,
  IUpsertMultiplePagesMessage,
  IUpsertPagePayload,
} from './../adapters/paginated-state-adapter/interfaces';
import { IEnhancedPaginatedResponse } from './../interfaces/paginated-response';
import { Id } from './../interfaces/persistence-service';
import { getIndexValue, IndexKey, IUpdateMetaData } from './cached-state';
import { IIndexField, IMultipleIndexField, IUniqueIndexField } from './interfaces';
import { StateService } from './state-service';

interface IMultiPageMessageData<T> {
  response: IPaginatedResponse<T>;
  namespace: string;
  pageNumber: number;
}

interface IProcessedPage<T> {
  data: T[];
  actions: Action[];
}

const defaultInitialPages = [1];

const EMPTY_MULTI_INDEX = Object.freeze([]);

export abstract class BasePaginatedStateService<
  S,
  T,
  U extends IUpsertPageMessage<T, string>,
  C extends IClearPaginationMessage<string>,
  M extends Action,
  O = RequestOptions<any, { [key: string]: any }>,
  D = T,
> extends StateService<T, O, D> {
  protected store = inject(Store);
  // abstract get upsertMessageClass(): IUpsertPageMessageConstructor<U, T, IUpsertPagePayload<T>>;
  // abstract get clearMessageClass(): IClearPaginationMessageConstructor<C, {}>;
  get setCollectionMessage(): new (payload: { entities: T[] }) => Action {
    throw Error('Collection Message Not Set');
  }
  abstract get paginationSelectors(): IPaginationSelectors<T>;
  abstract get entityStateSelector(): MemoizedSelector<any, EntityState<T>>;
  abstract get upsertMultipleMessage(): IUpsertMultipleMessageConstructor<M, T>;

  // abstract get upsertMultiplePagesMessage(): UpsertMultiplePagesMessageConstructor<
  //IUpsertMultiplePagesMessage<T,string>,T, IUpsertMultiplePagesPayload<T>>;
  // abstract get setPaginatedLoadingStateMessage();
  // abstract get resetPaginatedLoadingStateMessage();
  abstract get paginationMessages();

  getNameSpacedState(namespace) {
    return this.store.pipe(
      select(this.paginationSelectors.selectNameSpacedState(namespace)),
      filter(
        (state) =>
          !!state && state.loadingState.isLoaded === true && state.loadingState.isLoading === false,
      ),
    );
  }

  getResourceEntities() {
    return this.store.pipe(select(this.paginationSelectors.selectResourceEntities));
  }

  getConsecutivePageNumbers(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectConsecutivePageNumbers),
    );
  }

  // todo: evaluate resource consumption
  getConsecutivePageIds(namespace) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectConsecutivePageIds),
    );
  }
  getConsecutivePageEntities(namespace: string): Observable<T[]> {
    return this.getConsecutivePageIds(namespace).pipe(
      switchMap((ids) => {
        if (ids.length) {
          return combineLatest(ids.map((id) => this.getOne(id)));
        }
        return of<T[]>([]);
      }),
    );
  }

  getPageEntityIds(namespace: string, page: number) {
    return this.getNameSpacedState(namespace).pipe(map((state) => state.pages[page]));
  }

  getPageIsLoaded(namespace, id) {
    return this.getPageEntityIds(namespace, id).pipe(map((ids) => !!ids));
  }

  getPageEntities(namespace: string, page: number) {
    return combineLatest([this.getNameSpacedState(namespace), this.getResourceEntities()]).pipe(
      map(([state, entities]) => getPageEntities<T>(page)(state, entities)),
    );
  }

  getPageEntitiesAfterLoading(namespace: string, page: number) {
    return combineLatest([this.isLoaded(namespace), this.getPageIsLoaded(namespace, page)]).pipe(
      filter(([namespaceLoaded, pageLoaded]) => namespaceLoaded && pageLoaded),
      mergeMap(() => this.getPageEntities(namespace, page)),
    );
  }

  getMultiPageEntities(namespace: string, pages: number[]) {
    return combineLatest([this.getNameSpacedState(namespace), this.getResourceEntities()]).pipe(
      map(([state, entities]) => getMultiPageEntities(pages)(state, entities)),
    );
  }

  getMultiPageEntitiesCount(namespace: string, pages: number[]) {
    return combineLatest([this.getNameSpacedState(namespace), this.getResourceEntities()]).pipe(
      map(([state, entities]) => getMultiPageEntitiesCount(pages)(state, entities)),
    );
  }

  loadAllPages<N extends IPaginatedResponse<any>>(
    namespace: string,
    requestOptions: O,
    filters: IQueryParams = {},
  ) {
    return this.performWithLoadingState(
      namespace,
      this.persistenceService
        .retrieveAllPages({ pageSize: 600, ...filters }, requestOptions)
        .pipe(mergeMap((results) => this.updateMultiplePages(results, namespace))),
    );
  }

  /**
   * Used to load all pages usually given some query parameters
   * eg.multiple ids
   *
   * @param {IQueryParams} [filters={}] defaults to empty ie full
   * @param {boolean} [full=false] whether to allow empty parameter list
   * @return {*}
   * @memberof BasePaginatedStateService
   */
  loadAll(filters: IQueryParams = {}, full = false) {
    if (full || Object.values(filters).every((v) => isEmptyFilterValue(v))) return of<M>();
    const params = { pageSize: 150, ...filters };
    return this.persistenceService.retrieveAll(params).pipe(
      map((results) =>
        this.getUpsertMultipleMessage(
          flatMap(results, (result) => result.results),
          { params },
        ),
      ),
    );
  }

  loadNext(namespace: string, requestOptions: O, filters: IQueryParams = {}) {
    return combineLatest([
      this.getConsecutivePageNumbers(namespace),
      this.getTotalPages(namespace),
    ]).pipe(
      first(),
      mergeMap(([pages, totalPages]) => {
        const nextPage = pages[pages.length - 1] + 1;
        if (totalPages >= nextPage) {
          return this.loadPage(namespace, requestOptions, nextPage, filters);
        } else {
          return of();
        }
      }),
    );
  }

  initializePagination(
    namespace: string,
    requestOptions: JsonRequestOptions<O>,
    filters: IQueryParams = {},
    resetState = false,
  ) {
    return concat(
      of(this.getClearPaginationMessage(namespace)),
      of(new this.paginationMessages.SetLoadingStateMessage({ namespace })),
      this.loadPage(namespace, requestOptions, 1, filters, false, resetState),
      of(new this.paginationMessages.ResetLoadingStateMessage({ namespace })),
    );
  }

  initializeMultiPagePagination(
    namespace: string,
    requestOptions: O,
    filters: IQueryParams = {},
    pages = defaultInitialPages,
  ) {
    return concat(
      of(this.getClearPaginationMessage(namespace)),
      of(new this.paginationMessages.SetLoadingStateMessage({ namespace })),
      this.loadMultiplePages(pages, namespace, requestOptions, filters),
      of(new this.paginationMessages.ResetLoadingStateMessage({ namespace })),
    );
  }

  processPage(result): IProcessedPage<T> {
    return {
      data: result.results,
      actions: [],
    };
  }
  refreshCurrentPage<Z extends IPaginatedResponse<any>>(
    namespace: string,
    requestOptions: O,
    filters: IQueryParams = {},
    processPage?: (result: Z) => IProcessedPage<T>,
  ) {
    return this.getCurrentPage(namespace).pipe(
      first(),
      mergeMap((page) =>
        this.loadPage(namespace, requestOptions, page, filters, false, false, processPage),
      ),
    );
  }
  refreshLoadedPages<Z extends IPaginatedResponse<any>>(
    namespace: string,
    requestOptions: O,
    filters: IQueryParams = {},
    processPage?: (result: Z) => IProcessedPage<T>,
  ) {
    return this.getConsecutivePageNumbers(namespace).pipe(
      first(),
      mergeMap((pages) => (pages.length ? pages : [1])),
      mergeMap((page) =>
        this.loadPage(namespace, requestOptions, page, filters, false, false, processPage),
      ),
    );
  }

  getLoadPageActions<Z extends IPaginatedResponse<any>>(
    result,
    namespace,
    page,
    resetState = false,
    metaData: IUpdateMetaData,
    processPage?: (result: Z) => IProcessedPage<T>,
  ) {
    const processedPage = processPage ? processPage(result) : this.processPage(result);
    const setStateAction = resetState
      ? this.getSetCollectionMessage(processedPage.data)
      : this.getUpsertMultipleMessage(processedPage.data, metaData);
    return merge(
      ...processedPage.actions.map((action) => of(action)),
      of(this.getUpsertPageMessage(result, namespace, page)),
      of(setStateAction),
    );
  }
  performWithLoadingState<A extends Action>(namespace, action$: Observable<A>) {
    return concat(
      of(new this.paginationMessages.SetLoadingStateMessage({ namespace })),
      action$,
      of(new this.paginationMessages.ResetLoadingStateMessage({ namespace })),
    );
  }
  loadPage<Z extends IPaginatedResponse<any>>(
    namespace: string,
    requestOptions: O,
    page: number,
    params: IQueryParams = {},
    recursiveLoadNext = false,
    resetState = false,
    processPage?: (result: Z) => IProcessedPage<T>,
  ): Observable<Action> {
    return this.performWithLoadingState(
      namespace,
      this.persistenceService
        .retrieve<Z>({ page, ...params }, requestOptions)
        .pipe(
          mergeMap((result) =>
            this.getLoadPageActions(result, namespace, page, resetState, { params }, processPage),
          ),
        ),
    );
  }

  loadPages(namespace: string, requestOptions: O, filters: IQueryParams = {}): Observable<Action> {
    return this.getLoadedPagesKeys(namespace).pipe(
      first(),
      mergeMap((pages) => this.loadMultiplePages(pages, namespace, requestOptions, filters)),
    );
  }

  loadMultiplePages(
    pages: number[],
    namespace: string,
    requestOptions: O,
    filters: IQueryParams = {},
  ) {
    return this.persistenceService
      .retrieveMultiplePages(pages, filters, requestOptions)
      .pipe(mergeMap((results) => this.updateMultiplePages(results, namespace)));
  }

  updateMultiplePages(
    results: IEnhancedPaginatedResponse<T>[],
    namespace: string,
    resetState = false,
  ) {
    const updatePayload = flatMap(results, (result) => result.results);
    return from([
      this.getUpsertMultiplePagesMessage(
        results.map((result) => ({
          response: result,
          namespace,
          pageNumber: result.pageNumber,
        })),
      ),
      resetState
        ? this.getSetCollectionMessage(updatePayload)
        : this.getUpsertMultipleMessage(updatePayload),
    ]);
  }

  getCurrentPage(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectCurrentPage),
    );
  }

  getTotalCount(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectTotalCount),
    );
  }

  getTotalPages(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectTotalPages),
    );
  }

  getPaginationEnd(namespace: string) {
    return combineLatest([this.getLoadedTotalPages(namespace), this.getTotalPages(namespace)]).pipe(
      map(([loadedTotalPages, totalPages]) => {
        if (loadedTotalPages < totalPages) {
          return false;
        }

        return true;
      }),
    );
  }

  getPageSize(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(select(this.paginationSelectors.selectPageSize));
  }

  getLoadedTotalPages(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectLoadedTotalPages),
    );
  }

  getLoadedPagesKeys(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectLoadedPagesKeys),
    );
  }

  getLoadedRowsCount(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectLoadedRowsCount),
    );
  }

  getCurrentPageRowsCount(namespace: string) {
    return this.getNameSpacedState(namespace).pipe(
      select(this.paginationSelectors.selectCurrentPageRowsCount),
    );
  }

  getLoadingState(namespace: string) {
    return this.store.pipe(select(this.paginationSelectors.selectLoadingState(namespace)));
  }

  isLoaded(namespace?: string) {
    namespace ??= 'default';
    return this.getLoadingState(namespace).pipe(
      map(
        (loadingState) =>
          !get(loadingState, 'isLoading', false) && get(loadingState, 'isLoaded', false),
      ),
      distinctUntilChanged(),
    );
  }

  isLoading(namespace?: string) {
    namespace ??= 'default';
    return this.getLoadingState(namespace).pipe(
      map((loadingState) => get(loadingState, 'isLoading', false)),
      distinctUntilChanged(),
    );
  }

  isPendingOrLoaded(namespace: string) {
    return this.getLoadingState(namespace).pipe(
      map(
        (loadingState) =>
          get(loadingState, 'isLoading', false) || get(loadingState, 'isLoaded', false),
      ),
    );
  }
  getEntityDict() {
    return this.store.pipe(
      select(this.entityStateSelector),
      map((entityState) => entityState.entities),
      distinctUntilChanged(),
    );
  }
  getIndexes() {
    return this.store.pipe(
      select(this.entityStateSelector),
      map((entityState) => get(entityState, 'indexes')),
      distinctUntilChanged(),
    );
  }

  getIndex<I extends Set<Id> | Id>(
    index: IIndexField,
    indexValue: Id | Record<string, Id>,
  ): Observable<I> {
    const indexVal = getIndexValue(index, indexValue);
    return this.getIndexes().pipe(
      map((indexes) => get(indexes, [index.fieldName, indexVal])),
      distinctUntilChanged(),
    );
  }

  getOneOrNone(id) {
    return this.getEntityDict().pipe(
      map((entities) => entities[id]),
      distinctUntilChanged(),
    );
  }

  getMultipleByIndex<I extends IndexKey>(index: IMultipleIndexField, value: I): Observable<T[]> {
    return combineLatest([
      this.getEntityDict(),
      this.getIndex<Set<Id>>(index, value).pipe(filter((x) => !!x)),
    ]).pipe(
      map(([entities, identifiers]) => {
        const objectList: T[] = [];
        for (const identifier of identifiers) {
          if (entities[identifier]) {
            objectList.push(entities[identifier]);
          }
        }
        return objectList;
      }),
    );
  }

  getMultipleByMultipleIndexes<I extends IndexKey>(
    index: IMultipleIndexField,
    values: I[],
  ): Observable<T[]> {
    return combineLatest(values.map((value) => this.getMultipleOrEmptyByIndex(index, value))).pipe(
      map((res) => flatMap(res)),
    );
  }

  getOneByUniqueIndex<I extends IndexKey>(index: IUniqueIndexField, value: I) {
    return this.getOneOrNoneByUniqueIndex(index, value).pipe(filter((x) => !!x));
  }

  // WARNING: use only when needed
  getForMultipleByUniqueIndex<I extends string | number>(index: IUniqueIndexField, values: I[]) {
    return combineLatest(values.map((value) => this.getOneByUniqueIndex(index, value)));
  }

  getOne(id): Observable<T> {
    return this.getOneOrNone(id).pipe(filter((x) => !isNil(x)));
  }

  getMultiple<I extends IndexKey>(ids: I[]) {
    return combineLatest(ids.map((id) => this.getOne(id)));
  }

  getOneAfterLoading(id, namespace = 'default') {
    return this.isLoaded(namespace).pipe(
      filter((isLoaded) => isLoaded === true),
      mergeMap(() => this.getOne(id)),
      distinctUntilChanged(),
    );
  }

  getAll(namespace: string = null): Observable<T[]> {
    const allEntities = isNil(namespace) || namespace === 'default';

    namespace = allEntities ? 'default' : namespace;

    return allEntities
      ? this.store.pipe(
          select(this.entityStateSelector),
          map((entities) => entities.entities),
          distinctUntilChanged(),
          map((entities) => Object.values(entities)),
          // // cspell:disable-next-line
          // traceCalls(this.constructor.name)
        )
      : this.getConsecutivePageEntities(namespace);
  }

  getAllAfterLoading(namespace: string = 'default') {
    return this.isLoaded(namespace).pipe(
      filter((isLoaded) => isLoaded === true),
      mergeMap(() => this.getConsecutivePageEntities(namespace)),
    );
  }
  protected getOneOrNoneByUniqueIndex<I extends IndexKey>(
    index: IUniqueIndexField,
    value: I,
  ): Observable<T> {
    return combineLatest([this.getEntityDict(), this.getIndex<Id>(index, value)]).pipe(
      map(([entities, identifier]) => entities[identifier]),
    );
  }
  protected getMultipleOrEmptyByIndex<I extends IndexKey>(
    index: IMultipleIndexField,
    value: I,
  ): Observable<T[]> {
    return combineLatest([this.getEntityDict(), this.getIndex<Set<Id>>(index, value)]).pipe(
      map(([entities, identifiers]) => {
        if (!identifiers) return EMPTY_MULTI_INDEX as T[];
        const objectList: T[] = [];

        for (const identifier of identifiers) {
          objectList.push(entities[identifier]);
        }
        return objectList;
      }),
      distinctUntilChanged(),
    );
  }
  private getPagePayloadFromData(
    response: IPaginatedResponse<T>,
    namespace: string,
    pageNumber: number,
  ): IUpsertPagePayload<T> {
    const { count, results, next, previous } = response;
    return {
      count,
      results,
      next,
      previous,
      namespace,
      pageNumber,
    };
  }

  private getUpsertPageMessage(
    response: IPaginatedResponse<any>,
    namespace: string,
    pageNumber: number,
  ): U {
    return new this.paginationMessages.UpsertPageMessage(
      this.getPagePayloadFromData(response, namespace, pageNumber),
    );
  }

  private getUpsertMultiplePagesMessage(
    data: IMultiPageMessageData<T>[],
  ): IUpsertMultiplePagesMessage<T, string> {
    const pages = data.map((d) =>
      this.getPagePayloadFromData(d.response, d.namespace, d.pageNumber),
    );
    return new this.paginationMessages.UpsertMultiplePagesMessage({ pages });
  }

  private getClearPaginationMessage(namespace: string): C {
    return new this.paginationMessages.ResetPaginationMessage({ namespace });
  }

  private getUpsertMultipleMessage(entities: T[], metaData?: IUpdateMetaData): M {
    return new this.upsertMultipleMessage({ entities, metaData });
  }

  private getSetCollectionMessage(entities: T[]): Action {
    return new this.setCollectionMessage({ entities });
  }
}

export abstract class PaginatedStateService<
  T,
  U extends IUpsertPageMessage<T, string>,
  C extends IClearPaginationMessage<string>,
  M extends Action,
  O = RequestOptions<any, { [key: string]: any }>,
  D = T,
> extends BasePaginatedStateService<AppState, T, U, C, M, O, D> {}

export abstract class FilterStateService<
  T,
  U extends IUpsertPageMessage<T, string>,
  C extends IClearPaginationMessage<string>,
  M extends Action,
  O = RequestOptions<any, { [key: string]: any }>,
  D = T,
> extends PaginatedStateService<T, U, C, M, O, D> {
  protected abstract readonly scope: string[];
  protected abstract microAppService: MicroAppService;

  get isFilterEnabled$() {
    return this.microAppService.getMicroAppCode().pipe(
      map((code) => this.scope.indexOf(code) > -1),
      distinctUntilChanged(),
    );
  }

  getAllAfterLoading(namespace?: string) {
    return this.isLoaded(namespace).pipe(
      filter((isLoaded) => isLoaded === true),
      switchMap((x) => this.getAll(namespace)),
    );
  }
  getAllFiltersAfterLoading() {
    return this.isLoaded('default').pipe(
      filter((isLoaded) => isLoaded === true),
      switchMap((x) => this.getAllFilters()),
    );
  }
  getAllFilters() {
    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.getAll();
        }
        return of<T[]>([]);
      }),
    );
  }
}
