import { Action } from '@ngrx/store';
import { MarkAsDirtyAction } from 'ngrx-forms';

import { MarkMultipleAsDirtyAction } from './actions';

export function makeMarkMultipleAsDirtyReducer<T>(formStateReducer: (s: T, a: Action) => T) {
  function markMultipleAsTouched(state: T, action: MarkMultipleAsDirtyAction) {
    if (action.type === MarkMultipleAsDirtyAction.TYPE) {
      for (const controlId of action.payload.controlIds) {
        state = formStateReducer(state, new MarkAsDirtyAction(controlId));
      }
    }
    return state;
  }
  return markMultipleAsTouched;
}
