import { Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ISubresourcePaginationState, ResourceIdType } from '.';
import { IPaginationState } from '../../interfaces/pagination';
import { ILoadingStateAdapter } from '../loading-state-adapter/interfaces';
import {
  getConsecutivePageNumbers,
  getCurrentPage,
  getMultiPageEntities,
  getPageEntities,
} from '../paginated-state-adapter';

export function createSelectorFactory<T>(
  adapter: EntityAdapter<T>,
  loadingStateAdapter: ILoadingStateAdapter
) {
  const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

  function getEntitySelectors() {
    return {
      selectIds,
      selectEntities,
      selectAll,
      selectTotal,
    };
  }

  function getSubresourcePaginationSelectors(
    entitySelector: MemoizedSelector<object, EntityState<T>>,
    statePath = 'pagination'
  ) {
    const getResourcePagination =
      (resourceId: ResourceIdType) => (state: ISubresourcePaginationState) => {
        if (state && state[resourceId]) {
          return state[resourceId];
        }

        return null;
      };

    const selectState: MemoizedSelector<object, ISubresourcePaginationState> = createSelector(
      entitySelector,
      (state) => get(state, statePath)
    );

    const selectNamespacedResourcePagination = (resourceId: ResourceIdType) =>
      createSelector(selectState, getResourcePagination(resourceId));

    const selectResourcePagination: (
      namespace: string,
      resourceId: ResourceIdType
    ) => MemoizedSelector<object, IPaginationState> = (namespace: string, resourceId) =>
      createSelector(selectNamespacedResourcePagination(resourceId), (state) =>
        get(state, namespace)
      );

    const selectConsecutivePageNumbers = (namespace: string, resourceId: ResourceIdType) =>
      createSelector(selectResourcePagination(namespace, resourceId), getConsecutivePageNumbers);

    const selectStateEntities = createSelector(entitySelector, selectEntities);

    const selectPageEntities = (namespace: string, resourceId: ResourceIdType, page: number) =>
      createSelector(
        selectResourcePagination(namespace, resourceId),
        selectStateEntities,
        getPageEntities(page)
      );

    const selectMultiPageEntities = (
      namespace: string,
      resourceId: ResourceIdType,
      pages: number[]
    ) =>
      createSelector(
        selectResourcePagination(namespace, resourceId),
        selectStateEntities,
        getMultiPageEntities(pages)
      );

    const selectConsecutivePageEntities = (namespace: string, resourceId: ResourceIdType) =>
      createSelector(
        selectResourcePagination(namespace, resourceId),
        selectStateEntities,
        selectConsecutivePageNumbers(namespace, resourceId),
        (state: IPaginationState, entities: Dictionary<T>, pageNumbers: number[]) =>
          getMultiPageEntities<T>(pageNumbers)(state, entities)
      );

    const selectCurrentPage = (namespace: string, resourceId: ResourceIdType) =>
      createSelector(selectResourcePagination(namespace, resourceId), getCurrentPage);

    return {
      selectConsecutivePageNumbers,
      selectPageEntities,
      selectMultiPageEntities,
      selectConsecutivePageEntities,
      selectResourcePagination,
      selectCurrentPage,
    };
  }
  return {
    getSubresourcePaginationSelectors,
    getEntitySelectors,
  };
}
