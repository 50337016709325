import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isArray, isDate } from 'lodash-es';
import { Observable } from 'rxjs';

import { getDateFromDateString, isDateString } from '@locumsnest/core/src/lib/types';

import { getNaiveDateString } from './../types/date-time';
import { BaseRequestTransformInterceptor } from './base-request-transform.interceptor';
import { HttpEndpointService } from './http-endpoint.service';

@Injectable()
export class HttpApiDateInterceptor<T>
  extends BaseRequestTransformInterceptor
  implements HttpInterceptor
{
  constructor(protected httpEndpointService: HttpEndpointService<T>) {
    super();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.httpEndpointService.defersSerialization(req.url)) {
      return next.handle(req);
    }
    return super.intercept(req, next);
  }
  protected transformRequest(body) {
    return this.convertFromDate(body);
  }

  protected transformResponse(body) {
    return this.convertToDate(body);
  }

  private convertToDate(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }
    if (isArray(body)) {
      return body.map((val) => this.convertToDate(val));
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isDateString(value)) {
        body[key] = getDateFromDateString(value);
      } else if (typeof value === 'object') {
        body[key] = this.convertToDate(value);
      }
    }
    return body;
  }

  private convertFromDate(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }
    if (isArray(body)) {
      return body.map((val) => this.convertFromDate(val));
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isDate(value)) {
        body[key] = getNaiveDateString(value);
      } else if (typeof value === 'object') {
        body[key] = this.convertFromDate(value);
      }
    }
    return body;
  }
}
