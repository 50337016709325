/* eslint-disable prefer-spread */

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { get, trim } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, skipLast } from 'rxjs/operators';

import { extractPath, unquoteSecureCookieValue } from '@locumsnest/core/src/lib/helpers/util';

import { getParentUrl } from '../helpers/util';
import { selectParams, selectQueryParams, selectRoute, selectUrl } from './router-selectors';

export const EXTERNAL_ROUTES = new InjectionToken<string[]>('EXTERNAL_ROUTES');

interface IParamOptions {
  defaultValue?: boolean;
  url?: RegExp;
}

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(
    @Inject(EXTERNAL_ROUTES) private externalRoutes,
    private cookieService: CookieService,
    private store: Store,
    private router: Router,
  ) {}

  getSandBoxPath = () => {
    const sandBoxPath = this.cookieService.get('ln_sandbox');
    return unquoteSecureCookieValue(sandBoxPath);
  };

  isSandBoxed(path, sandBoxPath = null) {
    if (!sandBoxPath) {
      sandBoxPath = this.getSandBoxPath();
    }
    return !!sandBoxPath && trim(sandBoxPath, '/') === trim(path, '/');
  }

  getRoute(): Observable<{ state: ActivatedRoute }> {
    return this.store.pipe(select(selectRoute));
  }

  getCurrentUrl(): Observable<string> {
    return this.store.pipe(select(selectUrl));
  }

  getCurrentPath(): Observable<string> {
    return this.getCurrentUrl().pipe(map(extractPath), distinctUntilChanged());
  }

  getPreviousPath(): Observable<string> {
    return this.getCurrentPath().pipe(
      skipLast(1),
      map((route) => route),
    );
  }

  getQueryParams(): Observable<{ [key: string]: string }> {
    return this.store.pipe(select(selectQueryParams));
  }

  getPathParams(): Observable<{ [key: string]: string }> {
    return this.store.pipe(select(selectParams));
  }

  getSiblingRoute(newPath): Observable<string> {
    return this.getCurrentPath().pipe(map((path) => `${getParentUrl(path)}/${newPath}`));
  }

  getRouterPath(path: string) {
    return trim(path, '/').split('/');
  }

  navigateToSiblingRoute<T>(newPath) {
    return this.getSiblingRoute(newPath).pipe(
      first(),
      mergeMap((path) => {
        this.router.navigate([path]);
        return of<T>();
      }),
    );
  }

  getQueryParam<T = any>(
    path: string,
    optional = false,
    paramOptions?: IParamOptions,
  ): Observable<T> {
    const params$ = this.getRoute();
    // until typings get fixed https://github.com/ReactiveX/rxjs/issues/3989
    return params$.pipe.apply(
      params$,
      this.getPropertyTransformations(['state', 'queryParams', path], optional, paramOptions),
    );
  }

  getPathParam<T = any>(
    path: string,
    optional = false,
    paramOptions?: IParamOptions,
  ): Observable<T> {
    const params$ = this.getRoute();
    // until typings get fixed https://github.com/ReactiveX/rxjs/issues/3989
    return params$.pipe.apply(
      params$,
      this.getPropertyTransformations(['state', 'params', path], optional, paramOptions),
    );
  }

  isCurrentRouteInternal(): Observable<boolean> {
    return this.getCurrentUrl().pipe(map((value) => !this.externalRoutes?.includes(value)));
  }

  isCurrentRouteSandBoxed(): Observable<boolean> {
    return this.getCurrentPath().pipe(
      map((path) => this.isSandBoxed(path)),
      distinctUntilChanged(),
    );
  }

  getShareProfileUrl(): Observable<string> {
    return this.getPathParam('id').pipe(
      first(),
      map((id) => `/api/profile/${id}/share/`),
    );
  }

  addViewQueryParam(value): void {
    this.router.navigate([], {
      queryParams: { view: value },
      queryParamsHandling: 'merge',
    });
  }

  enableFilters(): void {
    this.router.navigate([], {
      queryParams: { hasFilter: true },
      queryParamsHandling: 'merge',
    });
  }

  addQueryParams(filters: Record<string, unknown>): void {
    this.router.navigate([], {
      queryParams: { ...filters },
      replaceUrl: true,
    });
  }

  hasFilters(): Observable<boolean> {
    return this.getQueryParam('hasFilter', true);
  }

  private getPropertyTransformations(
    path: string[],
    optional: boolean,
    paramOptions?: IParamOptions,
  ): OperatorFunction<any, unknown>[] {
    const defaultValue = get(paramOptions, 'defaultValue');
    const url = get(paramOptions, 'url');

    const transformations = [
      map((state) => {
        const a = get(state, path, defaultValue);
        return a;
      }),
    ];

    if (url) {
      const isCurrentUrl = (state) => {
        const a = url.test(trim(get(state, ['state', 'url']), '/'));
        return a;
      };

      transformations.unshift(filter(isCurrentUrl));
    }

    if (!optional) {
      transformations.push(filter((value) => !!value));
    }

    transformations.push(distinctUntilChanged());
    return transformations;
  }
}
