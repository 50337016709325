import { dateField, IFieldMap } from './fields';

const BASE_FIELDS: IFieldMap<{ createdAt: Date; lastUpdate: Date }> = {
  createdAt: dateField,
  lastUpdate: dateField,
};

export const getEntityFields = <S>(
  fields: IFieldMap<S>,
  baseFields = BASE_FIELDS,
): IFieldMap<S> => ({ ...fields, ...baseFields });
