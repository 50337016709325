import { Action } from '@ngrx/store';
import {
  box,
  Boxed,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required, requiredTrue } from 'ngrx-forms/validation';

import { minArrayLength } from '../../ngrx/validator';
import { ICloneDocumentToHospitalFormState } from './interfaces';

export const DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE = {
  documents: box([]),
  hospitals: box([]),
  gdprCheck: false,
  legalCheck: false,
};

const createCloneDocumentToHospitalFormGroup = () =>
  updateGroup<ICloneDocumentToHospitalFormState>({
    documents: validate<Boxed<number[]>>([required, minArrayLength(1, '')]),
    hospitals: validate<Boxed<number[]>>([required, minArrayLength(1, '')]),
    gdprCheck: validate<boolean>([requiredTrue]),
    legalCheck: validate<boolean>([requiredTrue]),
  });

export const validationReducer = createCloneDocumentToHospitalFormGroup();
export const createCloneDocumentToHospitalFormStateReducer = (
  formId,
  INITIAL_FORM_STATE = DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE
) => {
  const INITIAL_FORM_REDUCER_STATE = createFormGroupState(formId, INITIAL_FORM_STATE);
  const reducer =
    createFormStateReducerWithUpdate<ICloneDocumentToHospitalFormState>(validationReducer);
  return (
    state: FormGroupState<ICloneDocumentToHospitalFormState> = INITIAL_FORM_REDUCER_STATE,
    message: Action
  ) => reducer(state, message);
};
