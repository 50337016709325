import { ActivatedRoute } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

const getParams = (obj) => get(obj, ['state', 'params'], {}) as { [key: string]: string };

const getQueryParams = (obj) => get(obj, ['state', 'queryParams'], {}) as { [key: string]: string };

const getUrl = (obj) => get(obj, ['state', 'url'], '') as string;

export const selectRoute = createFeatureSelector<{ state: ActivatedRoute }>('router');

export const selectParams = createSelector(selectRoute, getParams);

export const selectQueryParams = createSelector(selectRoute, getQueryParams);

export const selectUrl = createSelector(selectRoute, getUrl);

export const selectParam = (param: string) =>
  createSelector(selectParams, (params) => params[param]);
