import { Action } from '@ngrx/store';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { IContactFormState, IContactFormValidator } from './interfaces';

export const DEFAULT_INITIAL_FORM_STATE = {
  message: '',
  subject: null,
};

export const DEFAULT_VALIDATORS = {
  message: [required],
  subject: [required],
};
const createContactFormGroup = (validators: IContactFormValidator) =>
  updateGroup<IContactFormState>({
    message: validate<string>(validators.message),
    subject: validate<number>(validators.subject),
  });

export const createContactFormReducer = (
  formId,
  validators: IContactFormValidator = DEFAULT_VALIDATORS,
  INITIAL_FORM_STATE = DEFAULT_INITIAL_FORM_STATE
) => {
  const group = createContactFormGroup(validators);
  const INITIAL_FORM_REDUCER_STATE = createFormGroupState(formId, INITIAL_FORM_STATE);
  const reducer = createFormStateReducerWithUpdate<IContactFormState>(group);
  return (state: FormGroupState<IContactFormState> = INITIAL_FORM_REDUCER_STATE, message: Action) =>
    reducer(state, message);
};
