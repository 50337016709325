import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { uniq } from 'lodash-es';
import { map, of } from 'rxjs';

import { getNavItemsByIds } from '@locumsnest/components/src/lib/interfaces/nav-item';

import { SetConfigurationMessage } from './micro-app.messages';
import { MicroAppService } from './micro-app.service';
import { AddNavItemToConfigurationSignal, SetConfigurationSignal } from './micro-app.signals';

@Injectable()
export class MicroAppEffects {
  initializeLoginFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetConfigurationSignal>(SetConfigurationSignal.TYPE),
      map((action) => new SetConfigurationMessage(action.payload)),
    ),
  );

  addNavItemToConfigurationSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddNavItemToConfigurationSignal>(AddNavItemToConfigurationSignal.TYPE),
      concatLatestFrom((action) => [
        this.microAppService.getMicroAppConfiguration(),
        of(getNavItemsByIds(action.payload.code, action.payload.ids)),
      ]),
      map(([, configuration, navItems]) => {
        const existingConfiguration = [...configuration.navItems];
        existingConfiguration.splice(2, 0, ...navItems);

        let conf = {
          ...configuration,
          navItems: existingConfiguration,
        };

        conf = {
          ...conf,
          navItems: uniq(conf.navItems),
        };

        return new SetConfigurationMessage({ configuration: conf });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private microAppService: MicroAppService,
  ) {}
}
