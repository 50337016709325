import { Action } from '@ngrx/store';
import { MessageableFactory } from '../../ngrx';
import { ILoadingState } from './interfaces';

export const DEFAULT_INITIAL_LOADING_STATE: ILoadingState = {
  isLoaded: false,
  isLoading: false,
};

export function createLoadingStateFactory<F extends string>(
  messageableFactory: MessageableFactory<F>,
  id: string
) {
  class SetLoadingMessage extends messageableFactory.create<string, { namespace?: string }>(
    'Set Loading' + id
  ) {}
  class ResetLoadingMessage extends messageableFactory.create<
    'Reset Loading',
    { namespace?: string }
  >('Reset Loading') {}

  function createReducer(initialState: ILoadingState = DEFAULT_INITIAL_LOADING_STATE) {
    const loadingStateReducer = (
      state: ILoadingState = initialState,
      action: SetLoadingMessage | ResetLoadingMessage | (Action & {})
    ): ILoadingState => {
      switch (action.type) {
        case SetLoadingMessage.TYPE:
          return {
            ...state,
            isLoading: true,
          };
        case ResetLoadingMessage.TYPE:
          return {
            ...state,
            isLoading: false,
            isLoaded: true,
          };
      }
      return state;
    };
    return loadingStateReducer;
  }
  function getMessages() {
    return {
      SetLoadingMessage,
      ResetLoadingMessage,
    };
  }

  return {
    createReducer,
    getMessages,
  };
}
