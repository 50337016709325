import { ValidationErrors } from '@angular/forms';
import { isFunction } from 'lodash-es';

import * as errorMessageHelper from './error-messages.helper';

//@todo return array and have a component for rendering it
export const parseValueErrors = (errors: ValidationErrors) =>
  Object.keys(errors)
    .map((key) => {
      if (isFunction(errorMessageHelper[key])) {
        return errorMessageHelper[key](errors[key]);
      }
      return '';
    })
    .filter((v) => v)
    .join('.\n');
