import { EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { MessageableFactory, SignalableFactory } from '../../ngrx';
import { createLoadingStateAdapter } from '../loading-state-adapter';
import { createPaginatedSubresourceStateFactory } from './paginated-subresource-state';
import { createSelectorFactory } from './paginated-subresource-state-selectors';

export function createPaginatedSubresourceStateAdapter<T, F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>,
  adapter: EntityAdapter<T>,
  getIdFunction?
) {
  const loadingStateAdapter = createLoadingStateAdapter<F>(messageableFactory, 'pagination');
  return {
    ...createPaginatedSubresourceStateFactory(
      signalableFactory,
      messageableFactory,
      adapter,
      loadingStateAdapter,
      getIdFunction
    ),
    ...createSelectorFactory(adapter, loadingStateAdapter),
  };
}
export * from './interfaces';
