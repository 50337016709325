import { Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { flatMap, get, isNil } from 'lodash-es';

import { IPaginationState } from '../../interfaces/pagination';
import { INameSpacedPaginationState } from './../../interfaces/pagination';
import { ILoadingStateAdapter } from './../loading-state-adapter/interfaces';
import { IPaginationSelectors } from './interfaces';

export interface FeatureState<T> {
  entityState: EntityState<T>;
  // TODO fix in some libraries that are using the old pagination syntax eg auth groups
  pagination?: INameSpacedPaginationState;
}

export const getPageEntities =
  <T>(pageNumber: number) =>
  (state: IPaginationState, entities: Dictionary<T>): T[] => {
    if (state) {
      const page = state.pages[pageNumber] as (string | number)[];
      if (state && state.pages[pageNumber] && entities) {
        return page.map((x: string | number) => entities[x] as T).filter((x) => !isNil(x));
      }
      return [];
    }

    return [];
  };

export const getTotalPages = (pagination: IPaginationState) => {
  let totalPages = 0;
  if (pagination && pagination.pageSize && pagination.totalCount) {
    totalPages = pagination.totalCount / pagination.pageSize;
  }
  return Math.ceil(totalPages);
};

export const getConsecutivePageNumbers = (pagination: IPaginationState) => {
  const totalPages = getTotalPages(pagination);
  const pages: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    if (!pagination.pages[i]) break;
    pages.push(i);
  }

  return pages;
};

export const getConsecutivePageIds = (pagination: IPaginationState) => {
  const totalPages = getTotalPages(pagination);
  const pages: (number[] | string[])[] = [];

  for (let i = 1; i <= totalPages; i++) {
    if (!pagination.pages[i]) break;
    pages.push(pagination.pages[i]);
  }

  return flatMap<string | number>(pages);
};

export const getMultiPageEntities =
  <T>(pageNumbers: number[]) =>
  (state: IPaginationState, entities: Dictionary<T>) => {
    const multiPageEntities = flatMap(
      pageNumbers.map((pageNumber: number) => getPageEntities<T>(pageNumber)(state, entities)),
    );
    return multiPageEntities;
  };

export const getMultiPageEntitiesCount =
  <T>(pageNumbers: number[]) =>
  (state: IPaginationState, entities: Dictionary<T>) =>
    flatMap(
      pageNumbers.map((pageNumber: number) => getPageEntities<T>(pageNumber)(state, entities)),
    ).length;

export const getCurrentPage = (pagination: IPaginationState) => get(pagination, 'currentPage');

const getPageSize = (state: IPaginationState): number => state?.pageSize || 0;

export function createSelectorFactory<T>(
  adapter: EntityAdapter<T>,
  loadingStateAdapter: ILoadingStateAdapter,
) {
  const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

  function getEntitySelectors() {
    return {
      selectIds,
      selectEntities,
      selectAll,
      selectTotal,
    };
  }

  function paginationSelectors(
    paginationSelector:
      | MemoizedSelector<object, FeatureState<T>>
      | MemoizedSelector<object, EntityState<T>>,
    loadingAdapter: ILoadingStateAdapter | null = null,
    statePath = 'pagination',
    entityStatePath = 'entityState',
    nested = true,
  ): IPaginationSelectors<T> {
    loadingAdapter ??= loadingStateAdapter;
    let selectState: MemoizedSelector<object, INameSpacedPaginationState>,
      selectEntitySelector: MemoizedSelector<object, EntityState<T>>;
    if (nested) {
      selectEntitySelector = paginationSelector as MemoizedSelector<object, EntityState<T>>;

      selectState = createSelector(selectEntitySelector, (state) => get(state, statePath));
    } else {
      selectState = createSelector(
        paginationSelector as MemoizedSelector<object, FeatureState<T>>,
        (state) => get(state, statePath),
      );
      selectEntitySelector = createSelector(
        paginationSelector as MemoizedSelector<object, FeatureState<T>>,
        (state) => get(state, entityStatePath),
      );
    }
    const selectResourceEntities = createSelector(selectEntitySelector, selectEntities);

    const selectNameSpacedState: (
      namespace: string,
    ) => MemoizedSelector<object, IPaginationState> = (namespace: string) =>
      createSelector(selectState, (state) => get(state, namespace));

    const getTotalCount = (state: IPaginationState): number => state?.totalCount || 0;

    const getTotalPagesForPaginationState = (state: IPaginationState): number =>
      state && state.currentPage > 0 && state.pages[1].length > 0
        ? Math.ceil(state.totalCount / state.pages[1].length)
        : 0;

    const getLoadedTotalPages = (state: IPaginationState): number =>
      state ? Object.keys(state.pages).length : 0;

    const getLoadedPagesKeys = (state: IPaginationState): number[] =>
      Object.keys(state.pages).map((k) => +k);

    const getLoadedRowsCount = (state: IPaginationState): number => {
      let count = 0;

      if (state) {
        for (const key in state.pages) {
          if (state.pages[key]) {
            count = count + state.pages[key].length;
          }
        }
      }

      return count;
    };

    const getCurrentPageRowsCount = (state: IPaginationState): number =>
      state?.currentPage > 0 ? state.pages[state.currentPage].length : 0;

    const selectConsecutivePageNumbers = getConsecutivePageNumbers;

    const selectTotalCount = getTotalCount;

    const selectTotalPages = getTotalPagesForPaginationState;

    const selectPageSize = getPageSize;

    const selectLoadedTotalPages = getLoadedTotalPages;

    const selectLoadedPagesKeys = getLoadedPagesKeys;

    const selectLoadedRowsCount = getLoadedRowsCount;

    const selectCurrentPageRowsCount = getCurrentPageRowsCount;

    const selectConsecutivePageIds = getConsecutivePageIds;

    const selectCurrentPage = getCurrentPage;

    const selectLoadingState = (namespace: string) =>
      loadingAdapter.getSelectors(selectNameSpacedState(namespace), 'loadingState')
        .selectLoadingState;

    return {
      selectNameSpacedState,
      selectResourceEntities,
      selectConsecutivePageNumbers,
      selectCurrentPage,
      selectTotalCount,
      selectTotalPages,
      selectLoadedTotalPages,
      selectLoadedPagesKeys,
      selectLoadedRowsCount,
      selectCurrentPageRowsCount,
      selectLoadingState,
      selectPageSize,
      selectConsecutivePageIds,
    };
  }
  return {
    paginationSelectors,
    getEntitySelectors,
  };
}
