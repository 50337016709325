/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';
import { get, isNil, isObject, isString } from 'lodash-es';
import { merge, Observable, of, timer } from 'rxjs';
import { filter, map, skip, takeUntil } from 'rxjs/operators';

import { isBadRequestError, isRequestError } from '../../helpers/error';
import { IAlertMessage, IAlertMessageConstructor, IResetMessageConstructor } from './interfaces';

type AlertType = 'error' | 'info' | 'success' | 'warn';

export interface IAlertData {
  message: string;
  type: AlertType;
}

interface IBadRequestMessageHandlerMap {
  [key: string]: BadRequestHandler;
}
type BadRequestHandler = (key: string, value: string) => string;
export interface IErrorHandlerMessageConfig<T = string> {
  errorEventMessageHandler?: (message: string) => string;
  errorDetailMessageHandler?: (message: T, errorCode?: string) => string;
  badRequestMessageHandlerMap?: IBadRequestMessageHandlerMap;
  unknownErrorMessage?: string;
}
type AlertHandler = (alertData: IAlertData) => Observable<Action>;

type ErrorHandler = (
  errorhandlerMessageConfig: IErrorHandlerMessageConfig,
) => (error: any, caught?: any) => Observable<Action>;

export const DEFAULT_ERROR_HANDLER_MESSAGE_CONFIG = {
  errorEventMessageHandler: (error: string) => error,
  errorDetailMessageHandler: (error: string, errorCode?: string) => error,
  unknownErrorMessage: 'An unexpected error occurred. Please Try Again later',
  badRequestMessageHandlerMap: {},
};

const createConditionalErrorHandler =
  (handle: ErrorHandler) =>
  (errorHandlerMessageConfig: IErrorHandlerMessageConfig = {}) =>
  (result: Action) => {
    if (isRequestError(result)) {
      return handle(errorHandlerMessageConfig)(result);
    }
    return of(result);
  };

const getExpiringMessage = <T extends IAlertMessage>(
  alertMessageAction: IAlertMessage,
  ResetMessageAction: IResetMessageConstructor<T>,
  inputStream: Observable<Action>,
) => {
  const message$ = merge(
    of(alertMessageAction),
    timer(7000).pipe(map(() => new ResetMessageAction({}))),
  );

  if (inputStream) {
    return message$.pipe(
      takeUntil(
        inputStream.pipe(
          skip(1),
          filter(
            (action: Action) =>
              action.type === ResetMessageAction.TYPE || action.type === alertMessageAction.type,
          ),
        ),
      ),
    );
  }

  return message$;
};

const createAlertHandler =
  <T extends IAlertMessage>(
    AlertMessageAction: IAlertMessageConstructor<T>,
    ResetMessageAction: IResetMessageConstructor<Action>,
  ) =>
  ({ message, type }: IAlertData, inputStream: Observable<Action> = null): Observable<Action> => {
    const alertMessageAction = new AlertMessageAction({
      message,
      type,
    });
    return getExpiringMessage(alertMessageAction, ResetMessageAction, inputStream);
  };
const parseError = (error) =>
  get(error, 'error.error.detail', get(error, 'error.detail', get(error, 'error.details', null)));

const createErrorHandler =
  <T extends IAlertMessage>(alertHandler: AlertHandler) =>
  (errorhandlerMessageConfig = {}) =>
  (error: any, caught): Observable<Action> => {
    const {
      errorDetailMessageHandler,
      unknownErrorMessage,
      errorEventMessageHandler,
      badRequestMessageHandlerMap,
    } = {
      ...DEFAULT_ERROR_HANDLER_MESSAGE_CONFIG,
      ...errorhandlerMessageConfig,
    };
    let message: string;

    if (error.error instanceof ErrorEvent) {
      message = errorEventMessageHandler(error.error.message);
    } else if (!isNil(parseError(error))) {
      const errorCode = error.error.error_code || '';
      message = errorDetailMessageHandler(parseError(error), errorCode);
    } else if (isBadRequestError(error)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      message = getBadRequestErrorMessage(error.error, '', badRequestMessageHandlerMap);
    }
    if (!message) {
      message = unknownErrorMessage;
    }
    return alertHandler({ message, type: 'error' });
  };

/**
 * Returns error message in default format
 *
 * @param {string} key
 * @param {string} value
 * @returns
 */
function defaultMessage(key: string, value: string) {
  if (key === 'non_field_errors') {
    return value;
  }

  return `${key} : ${value}`;
}

function getCompleteField(field: string, parentField: string) {
  if (field === 'non_field_errors') {
    return '';
  }

  if (parentField === '') {
    return field + ': ';
  } else return parentField + field + ': ';
}

function addMessage(currentMessage: string, addition: string) {
  if (currentMessage === '') {
    return addition;
  } else {
    return currentMessage + '<br>' + addition;
  }
}

/**
 * Constructs bad request messages
 *
 * @param {*} error error
 * @param {string} [parentGroupField=''] The parent parameter name
 * @param {{[key:string]: (k: string, v:string)=> string}}
 * @returns
 */
function getBadRequestErrorMessage(
  error,
  parentGroupField = '',
  messageMap: IBadRequestMessageHandlerMap = {},
) {
  let message = '';

  try {
    // loop over the error fields
    for (const field in error) {
      // if is an object
      if (isObject(error[field]) && !Array.isArray(error[field])) {
        message = addMessage(
          message,
          getBadRequestErrorMessage(
            error[field],
            getCompleteField(field, parentGroupField),
            messageMap,
          ),
        );
      } else if (Array.isArray(error[field])) {
        for (const msg of error[field]) {
          if (isString(msg)) {
            message = addMessage(
              message,
              get(messageMap, field, defaultMessage)(parentGroupField + field, msg),
            );
          } else {
            message = addMessage(
              message,
              getBadRequestErrorMessage(msg, getCompleteField(field, parentGroupField), messageMap),
            );
          }
        }
      }
    }
  } catch (e) {}

  return message;
}

export function createAlertHandlerFactory<T extends IAlertMessage>(
  AlertMessageAction: IAlertMessageConstructor<T>,
  ResetMessageAction: IResetMessageConstructor<Action>,
) {
  function getAlertHandlers() {
    const alertHandler = createAlertHandler(AlertMessageAction, ResetMessageAction);
    const errorHandler = createErrorHandler(alertHandler);
    const conditionalErrorHandler = createConditionalErrorHandler(errorHandler);
    return {
      errorHandler,
      conditionalErrorHandler,
      alertHandler,
    };
  }
  return {
    getAlertHandlers,
  };
}
