/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Action as IAction } from '@ngrx/store';

import { InvalidActionTypeError } from '@locumsnest/core/src/lib/ngrx/errors';
import { getFeatureMessageType, getFeatureSignalType } from '@locumsnest/core/src/lib/ngrx/helpers';
import {
  ActionNamespace,
  ActionType,
  FeatureActionTypeGetter,
  FeatureMessageTypeGetter,
  FeatureSignalTypeGetter,
  isActionType,
} from '@locumsnest/core/src/lib/ngrx/types';

export abstract class Action<T extends string, P> implements IAction {
  // this could be an abstract static property in future versions of typescript

  public static readonly TYPE;
  public type: T;
  constructor(public payload: P) {
    //this replaced the getter since its more compatible with redux tools
    this.type = (this.constructor as typeof Action).TYPE;
    if (!isActionType(this.type)) throw new InvalidActionTypeError(this.type);
  }
}

// our mixin can serve us well until there is any improvement
// in typescript's type safety for decorators

export const Actionable = <T extends string, P>(actionType: T) =>
  class Actionable extends Action<T, P> {
    static readonly TYPE = actionType;
  };

export abstract class ActionableFactory<F extends string, A extends ActionNamespace> {
  protected abstract actionTypeGetter: FeatureActionTypeGetter<F, A>;
  //creates an actionable class
  create<N extends string, P = {}>(actionName: N) {
    return Actionable<ActionType<F, A, N>, P>(this.actionTypeGetter<N>(actionName));
  }
  // public static abstract create (if abstract static classes were supported)
}
export class MessageableFactory<T extends string> extends ActionableFactory<
  T,
  ActionNamespace.MESSAGE
> {
  constructor(protected actionTypeGetter: FeatureMessageTypeGetter<T>) {
    super();
  }
  // can be used as the default provider if nod dependency injection is used
  public static forFeature<F extends string>(featureNamespace: F) {
    const featureActionTypeGetter = getFeatureMessageType<F>(featureNamespace);
    return new MessageableFactory<F>(featureActionTypeGetter);
  }
}

export class SignalableFactory<T extends string> extends ActionableFactory<
  T,
  ActionNamespace.SIGNAL
> {
  constructor(protected actionTypeGetter: FeatureSignalTypeGetter<T>) {
    super();
  }
  // can be used as the default provider if nod dependency injection is used
  public static forFeature<F extends string>(featureNamespace) {
    const featureActionTypeGetter = getFeatureSignalType<F>(featureNamespace);
    return new SignalableFactory<F>(featureActionTypeGetter);
  }
}

type IFeatureAction<F extends string, A extends ActionNamespace, N extends string, P> = Action<
  ActionType<F, A, N>,
  P
>;
export type ISignal<F extends string, N extends string, P> = IFeatureAction<
  F,
  ActionNamespace.SIGNAL,
  N,
  P
>;

export type IMessage<F extends string, N extends string, P> = IFeatureAction<
  F,
  ActionNamespace.MESSAGE,
  N,
  P
>;

export type IFeatureActionConstructor<
  F extends string,
  A extends ActionNamespace,
  N extends string,
  P,
> = (new (payload: P) => IFeatureAction<F, A, N, P>) & { TYPE: ActionType<F, A, N> };

export type ISignalConstructor<F extends string, N extends string, P> = IFeatureActionConstructor<
  F,
  ActionNamespace.SIGNAL,
  N,
  P
>;
export type IMessageConstructor<F extends string, N extends string, P> = IFeatureActionConstructor<
  F,
  ActionNamespace.MESSAGE,
  N,
  P
>;
