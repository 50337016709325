import { Action } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MessageableFactory, SignalableFactory } from '../../ngrx';
import { createAlertHandlerFactory } from './alert-handler';
import { IAlertMessagePayload, IAlertState } from './interfaces';

export const DEFAULT_INITIAL_STATE = {
  message: '',
  type: '',
  displayAlertMessage: false,
};

export function createAlertStateFactory<F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>
) {
  class ResetErrorMessage extends messageableFactory.create<'Reset Error', { message?: string }>(
    'Reset Error'
  ) {}
  class ResetErrorSignal extends signalableFactory.create<'Reset Error', { message?: string }>(
    'Reset Error'
  ) {}
  class AlertErrorMessage extends messageableFactory.create<'Alert Error', IAlertMessagePayload>(
    'Alert Error'
  ) {}
  class AlertErrorSignal extends signalableFactory.create<'Alert Error', { message?: string }>(
    'Alert Error'
  ) {}

  const alertSignalToMessage = pipe(
    filter(
      (action: Action) =>
        action.type === ResetErrorSignal.TYPE || action.type === AlertErrorSignal.TYPE
    ),
    map((action: ResetErrorSignal | AlertErrorSignal) =>
      action.type === ResetErrorSignal.TYPE
        ? new ResetErrorMessage(action.payload)
        : new AlertErrorMessage({ type: 'error', message: action.payload.message })
    )
  );

  function createReducer(
    resetState: any = DEFAULT_INITIAL_STATE,
    initialState: IAlertState = DEFAULT_INITIAL_STATE
  ) {
    const alertStateReducer = (
      state: IAlertState = initialState,
      action: AlertErrorMessage | ResetErrorMessage | (Action & { payload?: any })
    ): IAlertState => {
      switch (action.type) {
        case AlertErrorMessage.TYPE:
          return {
            ...state,
            ...action.payload,
            displayAlertMessage: true,
          };
        case ResetErrorMessage.TYPE:
          return {
            ...state,
            ...resetState,
            ...action.payload,
            displayAlertMessage: false,
          };
      }
      return state;
    };
    return alertStateReducer;
  }
  function getMessages() {
    return {
      ResetErrorMessage,
      AlertErrorMessage,
    };
  }
  function getSignals() {
    return {
      ResetErrorSignal,
      AlertErrorSignal,
    };
  }

  function getEffectOperators() {
    return {
      alertSignalToMessage,
    };
  }

  return {
    createReducer,
    getMessages,
    getSignals,
    getEffectOperators,
    ...createAlertHandlerFactory(AlertErrorMessage, ResetErrorMessage),
  };
}
