// export function endpoint(endpoint) {
//   return <T extends new (...args: any[]) => {}>(constructor: T) =>
//     class extends constructor {
//       endpoint = endpoint;
//     };
// }
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { joinPath } from '../helpers/file';

interface ServiceWithEndpoint {
  endpoint: string;
  new (...args: any): any;
}
interface DeferredSerializationConfig {
  endpoint: string;
  subResources: string[];
}
export type IndexType = string | symbol | number;
export interface IDeferredSerializationConfig<T extends IndexType> {
  endpoint: T;
  subResources?: string[];
}
const deferredSerializerEndpoints = {};
export const deferredSerializationEndpoints$ = new ReplaySubject<
  IDeferredSerializationConfig<IndexType>
>();

class Endpoint<T extends IndexType> extends String {
  endpoint: T;
}
export const isDeferred = <T extends string>(str: any): str is Endpoint<T> =>
  !!deferredSerializerEndpoints[str];
export type DeferredEndpoint<T extends IndexType> = string & Endpoint<T>;
export function defferSerialization<T extends string>({
  endpoint,
  subResources = [],
}: IDeferredSerializationConfig<T>): DeferredEndpoint<T> {
  deferredSerializerEndpoints[endpoint as string] = subResources;
  deferredSerializationEndpoints$.next({
    endpoint,
    subResources,
  });
  if (isDeferred<T>(endpoint)) {
    return endpoint;
  }
}
