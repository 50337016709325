export const environment = {
  api: '/',
  production: true,
  // cspell:disable-next-line
  recaptchaSiteKey: '6Lf5u_UUAAAAAFwXYMRa61WsHgXoRJHd7mVhYtnZ',
  reduxTools: false,
  sentry: {
    whiteList: ['https://webapp.locumsnest.co.uk', 'https://webapp.preprod.locumsnest.co.uk'],
    dsn: 'https://ffde9413687f4fa783d6a40c6017f73f@o1313600.ingest.sentry.io/6563935',
  },
  ephemeralOrigins: [
    'http://localhost:4200',
    'https://webapp.qa.locumsnest.co.uk',
    'https://webapp.demo.locumsnest.co.uk',
    'https://webapp.dev.locumsnest.co.uk',
  ],
  chameleonKey: 'SvzU5uOSSUKfsqttKBpfjyZ7lfMPddOWQkRFFuaRKADVYa-1LuMJN-COAYAcyFBrDMdDQA',
  firebase: {
    apiKey: 'AIzaSyDlFQkwUtosilCDGG7qjk8JMItKYEJbPfA',
    authDomain: 'locumsnest-aeeaf.firebaseapp.com',
    databaseURL: 'https://locumsnest-aeeaf.firebaseio.com',
    projectId: 'locumsnest-aeeaf',
    storageBucket: 'locumsnest-aeeaf.appspot.com',
    messagingSenderId: '146523801476',
    appId: '1:146523801476:web:0a5e17f9e82e1e50b47fd1',
    measurementId: 'G-NW2G1CV1W7',
    vapidKey:
      'BBtELnjCEEs-r6N4mSCLwtrx2qAR0yDyir8t8wKuuHZCST3XgtcS7bUBOlqvtGQVkt6JVET3oQHSrpGfMd79v3c',
  },
};
