import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import {
  get,
  isArray,
  isNil,
  isNumber,
  isPlainObject,
  isString,
  mapValues,
  pickBy,
  range,
  trimEnd,
} from 'lodash-es';
import { catchError, forkJoin, map, mergeMap, Observable, of, tap, throwError, zip } from 'rxjs';

import { inflect } from '@locumsnest/core/src/lib/helpers/inflector';
import { HttpEndpointService } from '@locumsnest/core/src/lib/http/http-endpoint.service';
import { IJsonHttpOptions } from '@locumsnest/core/src/lib/interfaces';
import {
  IEnhancedPaginatedResponse,
  IPaginatedResponse,
} from '@locumsnest/core/src/lib/interfaces/paginated-response';
import {
  ArrayBufferRequestOptions,
  Id,
  IPersistenceService,
  IQueryParams,
  JsonRequestOptions,
  Query,
  RequestOptions,
} from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { buildQueryParams } from '../helpers/util';
import {
  IApiEntity,
  IFieldMap,
  ISerializer,
  Serializer,
  SerializerApiEntity,
} from '../serializers';
import { Empty, NotEmpty } from '../types/misc';
import { DeferredEndpoint } from './endpoint.helper';

const noopSerializer = new Serializer({});
export interface ISerializedApiService<T extends string, V> {
  readonly endpoint: DeferredEndpoint<T>;
  readonly serializer: ISerializer<NotEmpty<IFieldMap<V>>, V>;
}
export interface IInterceptedAPiService<T extends string, V> {
  readonly endpoint: T;
  readonly serializer: ISerializer<Empty<IFieldMap<V>>, V>;
}
/**
 * @typeparam T endpoint configuration type
 * @typeparam V returned for retrieving a single resource
 * @typeparam D type send on update (UPDATE)
 * @typeparam L list/collection retrieval type (GET)
 * @typeparam P request options
 */
@Injectable()
export class HttpApiPersistenceService<
  T extends { [str: string]: any },
  V,
  L = IPaginatedResponse<V>,
  D = V,
  O extends RequestOptions<any> = RequestOptions<{}>,
> implements IPersistenceService<V, L, D, O>
{
  protected readonly defaultPageSize: number = 30;
  protected readonly endpoint: keyof T | DeferredEndpoint<keyof T>;
  protected readonly serializer = noopSerializer as ISerializer<Empty<IFieldMap<V>>, V>;
  protected readonly defaultHeaders: Record<string, string> = {};

  protected httpEndpointService = inject(HttpEndpointService<T>);
  protected http = inject(HttpClient);

  public static getParams(params) {
    if (isNil(params)) return {};
    // @todo decide on safe clone method for frozen object
    // and move to inflect
    params = JSON.parse(JSON.stringify(params));
    params = inflect(params, 'snakeCase');
    params = pickBy(
      params,
      (param) => (!isArray(param) && !isNil(param)) || (isArray(param) && param.length > 0),
    );
    const arrayParams = mapValues(pickBy(params, isArray), (v) => v.join(','));

    return {
      ...params,
      ...arrayParams,
    };
  }

  /**
   * To be used in the future if we want to load content negotiated data
   */
  public retrieveContent(query: Query, options: any): Observable<HttpResponse<ArrayBuffer>> {
    return this.retrieve<ArrayBuffer>(query, {
      ...options,
      httpOptions: {
        responseType: 'arraybuffer',
        observe: 'response',
        headers: {
          ...get(options, ['httpOptions', 'headers']),
          accept: options.accept,
        },
        ...get(options, 'httpOptions'),
      },
    });
  }

  public downloadFile(query?: Query, options?: O) {
    return this.retrieveContent(query, options).pipe(
      tap((resp: HttpResponse<ArrayBuffer>) => {
        const blob = new Blob([resp.body], { type: resp.headers.get('content-type') });
        const fileName = resp.headers
          .get('content-disposition')
          .split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim();
        saveAs(blob, fileName);
      }),
    );
  }

  public create<E = D, C = E, F = IJsonHttpOptions>(
    data: E,
    requestOptions?: O & { skipSerializer: true },
    httpOptions?: F,
  ): Observable<C>;
  public create<E extends V = V, C extends V = V, F = IJsonHttpOptions>(
    data: E,
    requestOptions?: O,
    httpOptions?: F,
  ): Observable<C | V> {
    const controllerResource = get(requestOptions, 'controllerResource', null);
    const pathParams = get(requestOptions, 'pathParams', {}) as keyof T;
    const skipSerializer = get(requestOptions, 'skipSerializer', false);
    const url = this.getEndpoint(controllerResource, null, pathParams);
    if (!skipSerializer) {
      data = this.serialize(data) as E;
    }
    if (skipSerializer) {
      return this.http.post<C>(url, data, httpOptions);
    } else {
      return this.http
        .post<IApiEntity<V, IFieldMap<V>>>(url, data, httpOptions)
        .pipe(map((r) => this.deserialize(r)));
    }
  }

  public bulkCreate<E = D[], C = E>(data: E, httpOptions?: IJsonHttpOptions) {
    const url = `${trimEnd(this.getEndpoint().replace(/my\/$/, ''), '/')}/bulk_create/`;
    return this.http.put<C>(url, data, httpOptions);
  }

  public bulkCreateFilter<E = D[], C = E>(data: E, httpOptions?: IJsonHttpOptions) {
    const url = `${trimEnd(this.getEndpoint().replace(/my\/$/, ''), '/')}/bulk_create/`;
    return this.http.post<C>(url, data, httpOptions);
  }

  public clear<E = D[], C = E>(data: Record<string, any>) {
    const url = `${trimEnd(this.getEndpoint().replace(/my\/$/, ''), '/')}/clear/`;
    const httpOptions: any = {
      responseType: 'json',
      ...this.getRetrievalOptions({ queryParams: data } as O),
    };

    return this.http.delete<C>(url, httpOptions);
  }

  public importData<E = D[], C = E>(data: E, httpOptions?: IJsonHttpOptions) {
    const url = `${trimEnd(this.getEndpoint().replace('my/', ''), '/')}/import_data/`;
    return this.http.put<C>(url, data, httpOptions);
  }

  public getUrl(query?: Query, options?: JsonRequestOptions<O>): string {
    const controllerResource = get(options, 'controllerResource', null);
    const id = isNumber(query) || isString(query) ? query : undefined;
    const params = isPlainObject(query) ? (query as IQueryParams) : undefined;
    const pathParams = get(options, 'pathParams');
    const url = this.getEndpoint(controllerResource, id, pathParams);
    return `${url}${buildQueryParams(params)}`;
  }

  public retrieve<R = L>(query?: IQueryParams, options?: JsonRequestOptions<O>): Observable<R>;
  public retrieve<R = V>(query: Id, options?: JsonRequestOptions<O>): Observable<R>;
  public retrieve<R = ArrayBuffer | HttpEvent<ArrayBuffer>>(
    query?: Query,
    options?: ArrayBufferRequestOptions<O> & { httpOptions: { observe: 'response' } },
  ): Observable<HttpResponse<R>>;
  public retrieve<R = ArrayBuffer | HttpEvent<ArrayBuffer>>(
    query?: Query,
    options?: ArrayBufferRequestOptions<O>,
  ): Observable<R>;
  public retrieve<R = V>(
    query?: Query,
    options?: JsonRequestOptions<O> | ArrayBufferRequestOptions<O>,
  ): Observable<R> | Observable<L> | Observable<ArrayBuffer | HttpEvent<ArrayBuffer>> {
    const controllerResource = get(options, 'controllerResource', null);
    const id = isNumber(query) || isString(query) ? query : undefined;
    const params = isPlainObject(query) ? query : undefined;
    const pathParams = get(options, 'pathParams');
    const skipSerializer = get(options, 'skipSerializer', false);
    const url = this.getEndpoint(controllerResource, id, pathParams);
    const httpOptions = this.getRetrievalOptions(options, params);
    // we sort out the type via method overloading/ no inference required
    if (skipSerializer) {
      return this.http.get<any>(url, httpOptions);
    } else {
      return this.http.get<any>(url, httpOptions).pipe(
        map((x) => {
          if (x.results) {
            return {
              ...x,
              results: x.results.map((r) => this.deserialize(r)),
            };
          }
          return this.deserialize(x);
        }),
      );
    }
  }
  deserialize(data: SerializerApiEntity<typeof this.serializer>) {
    return this.serializer.deserialize(data);
  }
  serialize(data: V) {
    return this.serializer.serialize(data);
  }
  getPagesFromFirstPage(firstPage) {
    const {
      count,
      results: { length: firstPageResultCount },
    } = firstPage;
    const pages = count / firstPageResultCount;
    return pages > 1 ? range(2, pages + 1) : [];
  }

  public retrieveAll(
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IPaginatedResponse<V>[]> {
    return this.retrieve<IPaginatedResponse<V>>(query).pipe(
      mergeMap((firstResult) => {
        const extraPageRange = this.getPagesFromFirstPage(firstResult);
        return zip(
          of(firstResult),
          ...extraPageRange.map((page) =>
            this.retrieve<IPaginatedResponse<V>>({ ...query, page }, options),
          ),
        );
      }),
    );
  }

  public retrieveAllPages(
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IEnhancedPaginatedResponse<V>[]> {
    return this.retrieve<IPaginatedResponse<V>>(query, options).pipe(
      mergeMap((firstResult) => {
        const extraPageRange = this.getPagesFromFirstPage(firstResult);
        return forkJoin([
          of({ ...firstResult, pageNumber: 1 }),
          ...extraPageRange.map((page) =>
            this.retrieve<IPaginatedResponse<V>>({ ...query, page }, options).pipe(
              map((response) => ({ ...response, pageNumber: page })),
              catchError((e) => of<IEnhancedPaginatedResponse<V>>()),
            ),
          ),
        ]);
      }),
    );
  }

  public retrieveMultiplePages(
    pages: number[],
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IEnhancedPaginatedResponse<V>[]> {
    return zip(
      ...pages.map((page) =>
        this.retrieve<IPaginatedResponse<V>>({ ...query, page }, options).pipe(
          map((response) => ({ ...response, pageNumber: page })),
          catchError((e) => {
            if (e instanceof HttpErrorResponse && e.status === 404)
              return of<IEnhancedPaginatedResponse<V>>(null);
            return throwError(() => e);
          }),
        ),
      ),
    ).pipe(map((results) => results.filter((x) => !isNil(x))));
  }

  public retrieveCurrent<R = L>(): Observable<R> {
    const endpoint = this.getEndpoint();
    let url = `${endpoint}/my/`;
    if (endpoint[endpoint.length - 1] === '/') url = `${endpoint}my/`;
    return this.http.get<any>(url);
  }
  public update(
    id: string | number | null,
    data: V,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O & { skipSerializer: true },
  ): Observable<V>;
  public update<U = D, M = U>(
    id: string | number | null,
    data: U,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O,
  ): Observable<M>;
  public update<U = D, M = U>(
    id: string | number | null,
    data: U | V,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O,
  ): Observable<M | V> {
    const controllerResource = get(requestOptions, 'controllerResource', null);
    const pathParams = get(requestOptions, 'pathParams', {}) as keyof T;
    const url = this.getEndpoint(controllerResource, id, pathParams);
    const skipSerializer = get(requestOptions, 'skipSerializer', false);
    if (skipSerializer) {
      return this.http.put<M>(url, data, httpOptions);
    } else {
      return this.http
        .put<IApiEntity<V, IFieldMap<V>>>(url, data, httpOptions)
        .pipe(map((r) => this.deserialize(r)));
    }
  }

  public delete<K = V>(
    id: string | number,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O,
  ) {
    const controllerResource = get(requestOptions, 'controllerResource', null);
    const pathParams = get(requestOptions, 'pathParams', {}) as keyof T;
    const url = this.getEndpoint(controllerResource, id, pathParams);
    httpOptions = {
      ...(httpOptions || { responseType: 'json' }),
      ...this.getRetrievalOptions(requestOptions),
    };

    return this.http.delete<K>(url, httpOptions);
  }

  public patch<A = D>(
    id: string | number,
    data,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O & { skipSerializer: true },
  ): Observable<A>;
  public patch<A = D>(
    id: string | number,
    data: A,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O,
  ): Observable<A>;
  public patch<A = D>(
    id: string | number,
    data: V,
    httpOptions?: Partial<IJsonHttpOptions>,
    requestOptions?: O,
  ): Observable<A | V> {
    const controllerResource = get(requestOptions, 'controllerResource', null);
    const pathParams = get(requestOptions, 'pathParams', {}) as keyof T;
    const url = this.getEndpoint(controllerResource, id, pathParams);
    const skipSerializer = get(requestOptions, 'skipSerializer', false);
    if (skipSerializer) {
      return this.http.patch<A>(url, data, httpOptions);
    } else {
      return this.http
        .patch<IApiEntity<V, IFieldMap<V>>>(url, data, httpOptions)
        .pipe(map((r) => this.deserialize(r)));
    }
  }

  public getEndpoint(controllerResource?: keyof T, id?: string | number, pathParams = {}): string {
    const endpoint = isNil(controllerResource) ? this.endpoint : controllerResource;
    const url = this.httpEndpointService
      .getEndpoint(endpoint)
      .replace(/\${(\w+)}/g, (_, v) => pathParams[v]);
    if (!isNil(id)) {
      return `${url}${id}/`;
    }
    return url;
  }

  private getRetrievalOptions(options: O, params?: any) {
    const optionQueryParams = get(options, 'queryParams', {});
    const queryParams = params
      ? {
          pageSize: this.defaultPageSize,
          ...params,
          ...optionQueryParams,
        }
      : optionQueryParams;

    return {
      ...get(options, 'httpOptions', {
        ...this.handleRequestHeaders(options),
      }),
      params: HttpApiPersistenceService.getParams(queryParams),
    };
  }

  private handleRequestHeaders(options: O): { headers: Record<string, string> } {
    return {
      headers: {
        ...this.defaultHeaders,
        ...get(options, ['httpOptions', 'headers']),
      },
    };
  }
}
