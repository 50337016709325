import { IField, IFieldMap, Omittable } from './fields';

export type IApiEntity<S, F extends IFieldMap<S>> = {
  [P in keyof S]: F[P] extends IField<S[P], infer Z> ? Z : S[P];
};

export type FieldMapEntity<T> = T extends IFieldMap<infer E> ? E : never;
export interface ISerializer<Z extends IFieldMap<S>, S = FieldMapEntity<Z>> {
  serialize(state: S): IApiEntity<S, Z>;
  deserialize(state: IApiEntity<S, Z>): S;
}
export interface IListSerializer<S, A> {
  serializer: IField<S, A>;
  serialize(state: S[]): A[];
  deserialize(state: A[]): S[];
}
export type IListSerializerConstructor<S, A> = new (f: IField<S, A>) => IListSerializer<S, A>;

export type SerializerApiEntity<T> = T extends ISerializer<infer F, infer S>
  ? IApiEntity<S, F>
  : never;
export class Serializer<Z extends IFieldMap<S>, S = FieldMapEntity<Z>>
  implements ISerializer<Z, S>, IField<S, IApiEntity<S, Z>>
{
  /**
   *
   *
   * @private
   * @type {boolean}
   * @memberof Serializer
   */
  private noop: boolean;
  constructor(private fields: Z) {
    this.noop = Object.keys(fields).length === 0;
  }

  public serialize(state: S): IApiEntity<S, Z> {
    if (this.noop) {
      return state as IApiEntity<S, Z>;
    }
    return this.performSerialization(state);
  }

  public deserialize(apiEntity: IApiEntity<S, Z>): S {
    if (this.noop) {
      return apiEntity as S;
    }
    return this.performDeserialization(apiEntity);
  }

  private performSerialization(state: S): IApiEntity<S, Z> {
    const customSerializedData: Partial<Z> = {};

    Object.keys(this.fields).forEach((fieldKey) => {
      const serializedFieldValue = this.fields[fieldKey].serialize(state[fieldKey]);
      const isNullableField = this.fields[fieldKey] instanceof Omittable;

      if (!isNullableField || (isNullableField && serializedFieldValue)) {
        customSerializedData[fieldKey] = serializedFieldValue;
      }
    });

    return {
      ...state,
      ...customSerializedData,
    } as IApiEntity<S, Z>;
  }

  private performDeserialization(state: IApiEntity<S, Z>): S {
    const customDeserializedData: Partial<S> = {};
    Object.keys(this.fields).forEach((fieldKey) => {
      const deserializedFieldValue = this.fields[fieldKey].deserialize(state[fieldKey]);
      const isNullableField = this.fields[fieldKey] instanceof Omittable;

      if (!isNullableField || (isNullableField && deserializedFieldValue)) {
        customDeserializedData[fieldKey] = deserializedFieldValue;
      }
    });

    return {
      ...state,
      ...customDeserializedData,
    } as S;
  }
}
export class ListSerializer<S, A> implements IListSerializer<S, A> {
  /**
   *
   *
   * @private
   * @type {boolean}
   * @memberof Serializer
   */
  constructor(public serializer: IField<S, A>) {}
  public serialize(state: S[]): A[] {
    return state.map((item) => this.serializer.serialize(item));
  }

  public deserialize(apiEntity: A[]): S[] {
    return apiEntity.map((item) => this.serializer.deserialize(item));
  }
}
