import { get, isEmpty } from 'lodash-es';
import { Boxed, FormGroupState, unbox, validate, ValidationErrors } from 'ngrx-forms';
import { minLength, required } from 'ngrx-forms/validation';

import { Time } from '../helpers';
import { DateTime } from '../helpers/date-time';
import { DATE_FORMAT_DB, TIME_FORMAT } from '../types/constants';

declare type ExprFn<T> = (t: T) => boolean;

export function requiredIf<T>(expr: ExprFn<T>): (value: T | null) => ValidationErrors {
  return (value: T | null): ValidationErrors => {
    if (!expr(value)) {
      return {};
    }
    if (value !== null && (value as any).length !== 0) {
      return {};
    }
    return {
      required: {
        actual: value,
      },
    };
  };
}

export function isInvalidIf<T>(expr: ExprFn<T>, error): (value: T | null) => ValidationErrors {
  return (value: T | null): ValidationErrors => {
    if (!expr(value)) {
      return {};
    }
    if (value !== null && (value as any).length !== 0) {
      return {};
    }
    return error;
  };
}

export const minTrimmedLength = (min: number) => {
  const minLengthValidator = minLength(min);
  return (value: string) => minLengthValidator(value.trim());
};

export const trimmedRequired = (value: string) => required(value.trim());

export const maxArrayLength =
  <T>(maxLength: number, verboseNamePlural: string = 'items') =>
  (value: Boxed<T[]>) => {
    const unboxedValue: T[] = unbox(value);
    const length = get(unboxedValue, 'length', 0);
    return length > maxLength
      ? { maxArrayLength: { maxLength, verboseNamePlural, providedLength: length } }
      : null;
  };

export const minArrayLength =
  <T>(minimumLength: number, verboseNamePlural: string = 'items') =>
  (value: Boxed<T[]>) => {
    const unboxedValue: T[] = unbox(value);
    const length = get(unboxedValue, 'length', 0);
    return length < minimumLength
      ? { minArrayLength: { minLength: minimumLength, verboseNamePlural, providedLength: length } }
      : null;
  };

export const minFormArrayLength =
  <T>(minimumLength: number, verboseNamePlural: string = 'items') =>
  (value: T[]) => {
    const length = get(value, 'length', 0);
    return length < minimumLength
      ? { minArrayLength: { minLength: minimumLength, verboseNamePlural, providedLength: length } }
      : null;
  };

export const validateDateTime = (dateTime: string) => {
  const dateString = DateTime.getDateFromPartialISOString(dateTime);
  const date = Time.getMoment(dateString, DATE_FORMAT_DB);
  const timeString = DateTime.getTimeFromPartialISOString(dateTime);
  const time = Time.getMoment(timeString, TIME_FORMAT);

  const errors: { [key: string]: any } = {};

  // eslint-disable-next-line no-useless-escape
  if (!time.isValid() || !/^(\d{2})\:(\d{2})$/.test(timeString)) {
    errors.invalidTime = true;
  }

  if (!date.isValid() || !/(\d{4})-(\d{2})-(\d{2})/.test(dateString)) {
    errors.invalidDate = true;
  }

  return isEmpty(errors) ? null : errors;
};

export const validateFutureDateTime = (verboseName) => (dateTime: string) => {
  if (!validateDateTime(dateTime)) {
    if (+Time.getMoment(dateTime) < Date.now()) {
      return { earlierThanToday: { verboseName } };
    }
  }

  return null;
};

interface IDateRangeValidatorConfiguration<T> {
  from: {
    verboseName: string;
    key: keyof T;
  };
  to: {
    verboseName: string;
    key: keyof T;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _validateDateRange =
  <T>({ from, to }: IDateRangeValidatorConfiguration<T>) =>
  (state: T) => {
    const fromValue = get(state, from.key);
    const toValue = get(state, to.key);

    if (!validateDateTime(fromValue) && !validateDateTime(toValue)) {
      //same timezone/unaware ISO strings are comparable
      return fromValue >= toValue ? { dateRangeInvalid: { from, to } } : null;
    }

    return null;
  };

export const validateDateRange =
  <T>(
    dateRangeConfiguration: IDateRangeValidatorConfiguration<T>,
    updateFn: (state: FormGroupState<T>) => FormGroupState<T>,
  ) =>
  (state: FormGroupState<T>) => {
    state = updateFn(state);

    return validate([_validateDateRange(dateRangeConfiguration)])(state);
  };
