import { ACTION_TYPE_REGEX } from '@locumsnest/core/src/lib/ngrx/constants';

export enum ActionNamespace {
  SIGNAL = 'SIGNAL',
  MESSAGE = 'MESSAGE',
}

export type ActionType<
  F extends string,
  A extends ActionNamespace,
  N extends string,
> = `[${F}] [${A}] ${N}`;

export const isActionType = <F extends string, A extends ActionNamespace, N extends string>(
  str: string,
): str is ActionType<F, A, N> => ACTION_TYPE_REGEX.test(str);

export type FeatureActionTypeGetter<F extends string, A extends ActionNamespace> = <
  N extends string,
>(
  actionName: N,
) => ActionType<F, A, N>;
export type FeatureMessageTypeGetter<F extends string> = FeatureActionTypeGetter<
  F,
  ActionNamespace.MESSAGE
>;
export type FeatureSignalTypeGetter<F extends string> = FeatureActionTypeGetter<
  F,
  ActionNamespace.SIGNAL
>;
