import { IMicroAppConfiguration } from './interfaces';
import { signalableFactory } from './micro-app.adapter';

export class SetConfigurationSignal extends signalableFactory.create<
  'Set Configuration',
  { configuration: IMicroAppConfiguration }
>('Set Configuration') {}

export class AddNavItemToConfigurationSignal extends signalableFactory.create<
  'Add NavItem To Configuration',
  { code: string; ids: number[] }
>('Add NavItem To Configuration') {}
