import { ISerializableFile } from '../interfaces';

export const isRelativeUrl = (url: string): boolean => url.startsWith('/');
export const isAbsoluteUrl = (url: string): boolean =>
  url.startsWith('http://') || url.startsWith('https://');
export const isUrl = (str) => isRelativeUrl(str) || isAbsoluteUrl(str);

export const serializedFile = (file) => {
  const f: ISerializableFile = {
    id: file.id,
    name: file.title,
    base64EncodedFile: file.file,
  };

  return JSON.stringify(f);
};

export const getFile = (file: string) => {
  try {
    const oFile: ISerializableFile = JSON.parse(file);
    return {
      id: oFile.id,
      title: oFile.name,
      file: oFile.base64EncodedFile,
    };
  } catch (e) {
    return null;
  }
};

export const getFilesArrayFromFile = (file: string) => {
  // this function supports some existing functionality

  const oFile = getFile(file);
  if (oFile) return [oFile];

  return [];
};

export const joinPath = (...a: string[]) => a.join('/').replace(/[/]+/g, '/');
