import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import { concat, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

import { RequestOptions } from '@locumsnest/core';
import { IQueryParams } from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { ILoadingStateMessages } from '../adapters/loading-state-adapter/interfaces';
import { IPaginatedResponse } from '../interfaces/paginated-response';
import {
  IAttributePersistenceService,
  IEntityStatePersistenceService,
} from '../interfaces/persistence-service';

export abstract class Fetchable {
  protected loaded = false;
  protected loading = false;
  protected get canLoad() {
    return !this.loading && !this.loaded;
  }

  handleFetch() {
    this.loading = true;
    return this.fetch().pipe(
      tap(() => {
        // when actual data is emitted
        this.loaded = true;
      }),
      catchError((err) => {
        // covers error
        this.loading = false;
        return throwError(() => err);
      }),
      finalize(() => {
        // covers cancellation or completion
        this.loading = false;
      })
    );
  }
  protected resetCanLoad() {
    this.loading = false;
    this.loaded = false;
  }
  protected abstract fetch(...args): Observable<any>;
}

/**
 *  The default Entity State Service
 *
 * @export
 * @abstract
 * @class StateService
 * @template T entity
 * @template O request options
 * @template D post data type
 * @template M action
 * @template C collection type
 */
export abstract class StateService<
  T,
  O = RequestOptions<any, { [key: string]: any }>,
  D = T,
  C = IPaginatedResponse<T>,
  M = Action
> extends Fetchable {
  protected abstract persistenceService: IEntityStatePersistenceService<T, O, D, C>;
  get isFilterEnabled$() {
    return of(true);
  }
  get loadingMessages(): ILoadingStateMessages | null {
    return null;
  }

  load(): Observable<M> {
    const namespace = 'default';
    if (this.canLoad) {
      if (this.loadingMessages) {
        return concat(
          of(new this.loadingMessages.SetLoadingMessage({ namespace })),
          this.handleFetch(),
          of(new this.loadingMessages.ResetLoadingMessage({ namespace }))
        );
      }
      return this.handleFetch();
    }
    return of();
  }

  silenceForbiddenError<E>() {
    return catchError<E, Observable<never>>((res: HttpErrorResponse) => {
      if (res.status === HttpStatusCode.Forbidden) return EMPTY;
      return throwError(() => res);
    });
  }

  protected getNamespace(
    requestOptions: O,
    queryParams: IQueryParams,
    identifiers: { [key: string]: string | number | boolean }
  ) {
    return [requestOptions, queryParams, identifiers]
      .map((obj) => JSON.stringify(obj, Object.keys(obj).sort()))
      .join('.');
  }

  abstract getAll(): Observable<T[]>;
  protected abstract fetch(...args): Observable<M>;
}

/**
 *  Attribute persistence service returns a non paginated response  eg a list
 *  of finite possible attributes values (eg Serialized Enum in backend)
 *
 * @export
 * @abstract
 * @class AttributeStateService
 * @template T Entity
 * @template S PersistenceService
 * @template F Filters
 * @template M Messages
 */
export abstract class AttributeStateService<T, F = {}, M = Action> extends Fetchable {
  protected abstract persistenceService: IAttributePersistenceService<T, F>;

  load(): Observable<Action> {
    if (this.canLoad) {
      return this.handleFetch();
    }
    return of<Action>();
  }

  getAllAfterLoading() {
    return this.getAll().pipe(filter((x) => !!x.length));
  }

  getStatusDict() {
    return this.getAllAfterLoading().pipe(map((statuses) => keyBy(statuses, 'val')));
  }

  abstract getAll(): Observable<T[]>;
}

export abstract class Loadable extends Fetchable {
  load(): Observable<Action> {
    if (this.canLoad) {
      return this.handleFetch();
    }
    return of<Action>();
  }
}
