import { Action } from '@ngrx/store';

import { getPaginationInitialState } from '../../helpers/pagination';
import { INameSpacedPaginationState, IPaginationState, Pages } from '../../interfaces/pagination';
import { MessageableFactory, SignalableFactory } from '../../ngrx';
import { ILoadingStateAdapter } from '../loading-state-adapter/interfaces';
import {
  IResetPaginationPayload,
  IUpsertMultiplePagesPayload,
  IUpsertPagePayload,
} from './interfaces';

export function createPaginatedStateFactory<F extends string, T>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>,
  loadingStateAdapter: ILoadingStateAdapter,
  getId = (entity) => {
    // eslint-disable-next-line no-console
    if (!entity.id) console.warn('Id is not present in Entity, please provide a getId function');
    return entity.id;
  },
) {
  class UpsertPageMessage extends messageableFactory.create<'Upsert Pages', IUpsertPagePayload<T>>(
    'Upsert Pages',
  ) {}
  class UpsertMultiplePagesMessage extends messageableFactory.create<
    'Upsert Multiple Pages',
    IUpsertMultiplePagesPayload<T>
  >('Upsert Multiple Pages') {}
  class ResetPaginationSignal extends signalableFactory.create<'Reset Pagination Signal', {}>(
    'Reset Pagination Signal',
  ) {}
  class ResetPaginationMessage extends messageableFactory.create<
    'Reset Pagination Message',
    IResetPaginationPayload
  >('Reset Pagination Message') {}
  type PaginationMessages =
    | UpsertPageMessage
    | UpsertMultiplePagesMessage
    | ResetPaginationMessage
    | (Action & { payload: any });

  const loadingStateMessages = loadingStateAdapter.getMessages();

  class SetLoadingStateMessage extends loadingStateMessages.SetLoadingMessage {}
  class ResetLoadingStateMessage extends loadingStateMessages.ResetLoadingMessage {}

  function pageSizeReducer(state: number = null, action: PaginationMessages) {
    switch (action.type) {
      case UpsertPageMessage.TYPE: {
        const payload = action.payload as IUpsertPagePayload<T>;

        if (!payload.next && payload.pageNumber > 1) {
          return (payload.count - payload.results.length) / (payload.pageNumber - 1);
        }

        return payload.results.length;
      }
    }

    return state;
  }
  function pagesReducer(state: Pages, action: PaginationMessages): Pages {
    switch (action.type) {
      case UpsertPageMessage.TYPE: {
        const payload = action.payload as IUpsertPagePayload<T>;
        return {
          ...state,
          [(payload as IUpsertPagePayload<T>).pageNumber]: payload.results.map((x) => getId(x)),
        };
      }
    }

    return state;
  }

  function createReducer(initialState: IPaginationState = getPaginationInitialState()) {
    const loadingStateReducer = loadingStateAdapter.createReducer();
    const paginationStateReducer = (
      state: IPaginationState = initialState,
      action: PaginationMessages,
    ): IPaginationState => {
      switch (action.type) {
        case UpsertPageMessage.TYPE: {
          const payload = action.payload as IUpsertPagePayload<T>;
          return {
            ...state,
            pageSize: pageSizeReducer(state.pageSize, action),
            currentPage: payload.pageNumber,
            totalCount: payload.count,
            pages: pagesReducer(state.pages, action),
          };
        }
        case ResetPaginationMessage.TYPE:
          return initialState;
          break;
        default: {
          const loadingState = loadingStateReducer(state.loadingState, action);
          if (loadingState !== state.loadingState) {
            return { ...state, loadingState };
          }
        }
      }
      return state;
    };

    const nameSpacedPaginationStateReducer = (
      state: INameSpacedPaginationState = {},
      action: PaginationMessages,
    ): INameSpacedPaginationState => {
      switch (action.type) {
        case UpsertMultiplePagesMessage.TYPE: {
          const messages = action.payload.pages.map((page) => new UpsertPageMessage(page));
          for (const message of messages) {
            state = nameSpacedPaginationStateReducer(state, message);
          }
          return state;
        }
        case UpsertPageMessage.TYPE: {
          const payload = action.payload as IUpsertPagePayload<T>;
          const namespace = payload.namespace;
          return {
            ...state,
            [namespace]: paginationStateReducer(state[namespace], action),
          };
        }
        case ResetPaginationMessage.TYPE: {
          const payload = action.payload as IResetPaginationPayload;
          const namespace = payload.namespace;
          return {
            ...state,
            [namespace]: paginationStateReducer(state[namespace], action),
          };
        }
        case SetLoadingStateMessage.TYPE:
        case ResetLoadingStateMessage.TYPE: {
          const payload = action.payload;
          const namespace = payload.namespace;

          if (!namespace) return state;

          return {
            ...state,
            [namespace]: paginationStateReducer(state[namespace], action),
          };
        }
      }

      return state;
    };
    return nameSpacedPaginationStateReducer;
  }
  function getMessages() {
    return {
      UpsertPageMessage,
      UpsertMultiplePagesMessage,
      ResetPaginationMessage,
      SetLoadingStateMessage,
      ResetLoadingStateMessage,
    };
  }
  function getSignals() {
    return {
      ResetPaginationSignal,
    };
  }

  return {
    createReducer,
    getMessages,
    getSignals,
    getLoadingMessages: loadingStateAdapter.getMessages,
  };
}
