import { ENDPOINT_CONFIG } from 'apps/hospital-admin/src/app/core/constants';
import { Observable } from 'rxjs';

import {
  IArrayBufferHttpOptions,
  IEnhancedPaginatedResponse,
  IHttpOptions,
  IJsonHttpOptions,
  IPaginatedResponse,
} from '../..';

export interface IQueryParams {
  [param: string]: string | number | boolean | string[] | number[];
}
export interface IUrlTags {
  [param: string]: string;
}

export type Id = string | number;
export type Query = Id | IQueryParams;
export enum MimeType {
  'csv' = 'text/csv',
  'xls' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'json' = 'application/json',
}

interface IBaseRequestOptions<P, Q> {
  pathParams?: P;
  queryParams?: Q;
  accept?: MimeType;
}

export interface IControllerResourceRequestOptions<P, Q> extends IBaseRequestOptions<P, Q> {
  controllerResource: keyof typeof ENDPOINT_CONFIG;
  skipSerializer: true;
}
export interface IRestResourceRequestOptions<P, Q> extends IBaseRequestOptions<P, Q> {
  skipSerializer?: boolean;
}
export type RequestOptions<P, Q = { [key: string]: any }> =
  | IControllerResourceRequestOptions<P, Q>
  | IRestResourceRequestOptions<P, Q>;

export interface IJsonRequestOptions {
  httpOptions?: Partial<IJsonHttpOptions>;
}
export interface IArrayBufferRequestOptions {
  httpOptions?: Partial<IArrayBufferHttpOptions>;
}

export type JsonRequestOptions<T extends RequestOptions<unknown>> = T & IJsonRequestOptions;
export type ArrayBufferRequestOptions<T extends RequestOptions<unknown>> = T &
  IArrayBufferRequestOptions;
export type HttpRequestOptions<T extends RequestOptions<unknown>> =
  | JsonRequestOptions<T>
  | JsonRequestOptions<T>;

/**
 * @template T Resource configuration
 * @template V type send on creation (POST, PATCH)
 * @template D type send on update (UPDATE)
 * @template L list/collection retrieval type (GET)
 * @template P request options
 */
export interface IPersistenceService<
  V,
  L = IPaginatedResponse<V>,
  D = V,
  O extends RequestOptions<any, any> = RequestOptions<{}, {}>,
> {
  create<C = D>(data: D, options?: O): Observable<C>;
  retrieve<R = L>(query?: IQueryParams, options?: JsonRequestOptions<O>): Observable<R>;
  retrieve<R = V>(query: Id, options?: JsonRequestOptions<O>): Observable<R>;
  retrieve<R = V>(query?: Query, options?: JsonRequestOptions<O>): Observable<R> | Observable<L>;
  retrieveAll(
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IPaginatedResponse<V>[]>;
  retrieveMultiplePages(
    pages?: number[],
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IEnhancedPaginatedResponse<V>[]>;
  retrieveAllPages(
    query?: IQueryParams,
    options?: JsonRequestOptions<O>,
  ): Observable<IEnhancedPaginatedResponse<V>[]>;
  update<U = D>(id: string | number, data: D, httpOptions?: IJsonHttpOptions): Observable<U>;
  delete<K = V>(id: string | number, httpOptions?: IJsonHttpOptions): Observable<D>;
  patch<A = D>(data, httpOptions?: IHttpOptions): Observable<D>;
}
/**
 *
 *
 * @export
 * @interface IEntityStatePersistenceService
 * @extends {IPersistenceService<E, IPaginatedResponse<E>, E, P>}
 * @template E entity type
 * @template P request options type
 * @template C collection type
 */
export interface IEntityStatePersistenceService<E, O, D = E, C = IPaginatedResponse<E>>
  extends IPersistenceService<E, C, D, O> {}

export interface IAttributePersistenceService<E, O> extends IPersistenceService<E, E[], E, O> {}
