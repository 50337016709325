import { isNil } from 'lodash-es';
import { box, Boxed, unbox } from 'ngrx-forms';

import { getDateFromDateString, getNaiveDateString } from '../types';

export interface IField<S, A> {
  deserialize(v: A): S;
  serialize(v: S): A;
}
export type IFieldMap<S> = {
  [P in keyof S]?: IField<S[P], any>;
};
export class OptionalField<S, A> {
  constructor(
    private field: IField<S, A>,
    private coalesce = false,
  ) {}

  public serialize(d: S) {
    if (this.coalesce) {
      const serialized = d && this.field.serialize(d);
      return serialized || null;
    }

    return isNil(d) ? d : this.field.serialize(d);
  }

  public deserialize(d: A) {
    if (this.coalesce) {
      const deserialized = d && this.field.deserialize(d);
      return deserialized || null;
    }
    return isNil(d) ? d : this.field.deserialize(d);
  }
}

export class Omittable<S, A> {
  constructor(private field: IField<S, A>) {}

  public serialize(d: S) {
    return isNil(d) ? d : this.field.serialize(d);
  }

  public deserialize(d: A) {
    return isNil(d) ? d : this.field.deserialize(d);
  }
}

export const dateField: IField<Date, string> = {
  deserialize: getDateFromDateString,
  serialize: getNaiveDateString,
};

export const numberField: IField<number, string> = {
  deserialize: (v: string) => parseInt(v, 10),
  serialize: (v: number) => v.toString(),
};

export const stringField: IField<string, string> = {
  deserialize: (v: string) => v,
  serialize: (v: string) => v,
};

// true <==> 'true'
// false <==> null
export const booleanField: IField<boolean, string> = {
  deserialize: (v: string) => !!v,
  serialize: (v: boolean) => (v ? 'true' : null),
};

export const boxedNumberField: IField<Boxed<number[]>, string> = {
  deserialize: (v: string | number[]) => {
    if (typeof v === 'string') return box(v.split(',').map((val) => +val));
    return box(v || null);
  },
  serialize: (v: Boxed<number[]>) => unbox(v)?.join(',') || null,
};

export const optionalDateField: IField<Date, string> = new OptionalField(dateField);

export const optionalNumberField: IField<number, string> = new OptionalField(numberField);

export const optionalStringField: IField<string, string> = new OptionalField(stringField);
