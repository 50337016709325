import { SignalableFactory, MessageableFactory } from '../../ngrx';
import { createAlertStateFactory } from './alert-state';
import { createSelectorFactory } from './alert-state-selectors';

export function createAlertStateAdapter<F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>
) {
  return {
    ...createAlertStateFactory(signalableFactory, messageableFactory),
    ...createSelectorFactory(),
  };
}
export * from './interfaces';
