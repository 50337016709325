import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class BaseRequestTransformInterceptor {
  public isTransFormable(body: any) {
    return !(body instanceof Blob);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isTransFormable(req.body)) {
      req = req.clone({ body: this.transformRequest(cloneDeep(req.body)) });
    }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && this.isTransFormable(event.body)) {
          this.transformResponse(event.body);
        }
      })
    );
  }
  protected abstract transformRequest(body: Record<string, any>): Record<string, any>;
  protected abstract transformResponse(body: Record<string, any>): Record<string, any>;
}
