import { MessageableFactory } from '../../ngrx';
import { createLoadingStateFactory } from './loading-state';
import { createSelectorFactory } from './loading-state-selectors';

export function createLoadingStateAdapter<F extends string>(
  messageableFactory: MessageableFactory<F>,
  id: string = 'main'
) {
  return {
    ...createLoadingStateFactory(messageableFactory, id),
    ...createSelectorFactory(),
  };
}
