export interface IEnumeration {
  readonly code: string;
  readonly display: string;
  readonly val: number;
}

export enum IArrowDirectionsEnum {
  LEFT,
  RIGHT,
}

export enum IStatusTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum DisplayButtons {
  None,
  Approve,
  Decline,
  Both,
}
