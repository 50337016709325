import { createSelector } from '@ngrx/store';

import { ILoadingState } from './interfaces';

function getSelectors<H>(stateSelector, statePath = 'entityLoadingState') {
  const selectLoadingState = (state: ILoadingState): ILoadingState => {
    if (state) return state[statePath];
  };
  return {
    selectLoadingState: createSelector(stateSelector, selectLoadingState),
  };
}
export function createSelectorFactory() {
  return {
    getSelectors,
  };
}
