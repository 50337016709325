import { capitalize, isNumber } from 'lodash-es';

export const numeric = (value) => {
  let val = value.actual;
  try {
    val = parseFloat(val);
  } catch (e) {
    // do nothing
  }

  return !isNumber(val) && 'Value should be a number';
};
const valueOrField = (value, defaultDescriptor: string = 'Value') => {
  if (value?.verboseName) {
    return capitalize(value.verboseName);
  }
  return defaultDescriptor;
};

const applyErrorField = (
  errors: Record<string, Record<string, any>>,
  field: string,
  value: string | boolean | number,
) => {
  for (const error in errors) {
    if (errors.hasOwnProperty(error)) {
      errors[error][field] = value;
    }
  }
  return errors;
};
export const applyVerboseName = (errors: Record<string, Record<string, any>>, value: string) =>
  applyErrorField(errors, 'verboseName', value);
export const applyPlural = (errors: Record<string, Record<string, any>>) =>
  applyErrorField(errors, 'plural', true);
export const greaterThan = (value) =>
  numeric(value) || `${valueOrField(value)} should be greater than ${value.comparand}`;
export const lessThan = (value) =>
  numeric(value) || `${valueOrField(value)}should be less than  ${value.comparand}`;
export const greaterThanOrEqualTo = (value) =>
  numeric(value) || `${valueOrField(value)} should be at least ${value.comparand}`;

export const lessThanOrEqualTo = (value) =>
  numeric(value) || `${valueOrField(value)} should be at most ${value.comparand}`;
export const required = (value) => `${valueOrField(value)} ${value.plural ? 'are' : 'is'} required`;
export const pattern = (value: Record<string, unknown>) => value?.message || `Invalid field format`;
export const maxLength = (value) =>
  `The maximum number of allowed characters is ${value.maxLength}`;
export const minLength = (value) =>
  `The minimum number of allowed characters is ${value.minLength}`;
export const maxArrayLength = (value) =>
  `The maximum number of allowed ${value.verboseNamePlural} is ${value.maxLength}`;
export const minArrayLength = (value) =>
  `The minimum number of allowed ${value.verboseNamePlural} is ${value.minLength}`;
export const earlierThanShift = (value) => `Should not precede timesheet's start time`;
export const greaterThanShift = (value) => `Should not come after timesheet's end time`;
export const earlierThanToday = (value) => `The ${value.verboseName} needs to be in the future`;
export const fromLaterThanTo = (value) => `The 'from' date cannot be later than the 'to' date`;
export const dateRangeInvalid = (value) =>
  `${value.to.verboseName} must be greater than ${value.from.verboseName}`;

export const invalidTime = (value) => `Invalid time format (hh:mm)`;
export const invalidDate = (value) => `Invalid date format`;

export const newPasswordLength = (value) => 'The new password must contain at least 8 characters';
export const newPasswordEmpty = (value) => 'The new password may not be blank';
export const passwordsNotMatch = (value) => 'The new passwords do not match';
export const oldPasswordEmpty = (value) => 'The old password may not be blank';

export const invalidVideoUrl = (value) => 'Invalid video url';
export const phonePattern = (value) =>
  `The ${value.verboseName} must contain ${value.count} number characters`;
export const invalidPhoneNumber = (value) => `The phone number is invalid`;

export const agencyFeePercentage = (value) => 'The agency fee percentage must be between 0 and 100';
export const agencyFee = (value) => 'The agency fee cannot be less than 0';
export const duplicateArrayValue = (value) => 'Value should be unique';
export const invalidRange = (value) =>
  `The ${value.verboseName} must be between ${value.min} and ${value.max}`;
