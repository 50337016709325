import { createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { IAlertState } from './interfaces';

function getSelectors<H>(statePath = 'alertState', stateSelector?) {
  const selectAlertState = (state: IAlertState) => state;
  const selectAlertMessage = (state: IAlertState) => state.message;
  const selectAlertType = (state: IAlertState) => state.type;
  const selectDisplayErrorMessage = (state: IAlertState) => state.displayAlertMessage;
  const selectState = stateSelector ? stateSelector : (state: H) => get(state, statePath);
  return {
    selectAlertState: createSelector(selectState, selectAlertState),
    selectAlertMessage: createSelector(selectState, selectAlertMessage),
    selectAlertType: createSelector(selectState, selectAlertType),
    selectDisplayErrorMessage: createSelector(selectState, selectDisplayErrorMessage),
  };
}
export function createSelectorFactory() {
  return {
    getSelectors,
  };
}
