import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

import { HTTP_API_CONFIG } from '@locumsnest/core/src/lib/http/providers';
import { IHttpApiConfiguration, IRetrievable } from '@locumsnest/core/src/lib/interfaces';
import { WINDOW } from '@locumsnest/core/src/lib/services/window.service';

@Injectable()
export class HttpApiConfigurationService<T> implements IRetrievable<IHttpApiConfiguration<T>> {
  private _httpApiConfiguration;
  constructor(
    @Inject(HTTP_API_CONFIG)
    httpApiConfiguration: IHttpApiConfiguration<T>,
    @Inject(WINDOW)
    private window,
  ) {
    this.httpApiConfiguration = httpApiConfiguration;
  }
  private get httpApiConfiguration() {
    return this._httpApiConfiguration;
  }
  private set httpApiConfiguration(httpApiConfiguration: IHttpApiConfiguration<T>) {
    const baseUrl = httpApiConfiguration.baseUrl || window.location.href;
    this._httpApiConfiguration = { ...httpApiConfiguration, baseUrl };
  }
  get<K extends keyof IHttpApiConfiguration<T>>(key: K): IHttpApiConfiguration<T>[K] {
    const configurationValue = this.httpApiConfiguration[key];
    if (isNil(this.httpApiConfiguration[key]))
      throw new Error(`HttpApi configuration not found for ${key}`);
    return configurationValue;
  }
  getEndpointPath<E extends keyof T>(endpoint: E): T[E] {
    const endpointPath = this.get('endpoints')[endpoint];
    if (isNil(endpointPath))
      throw new Error(`HttpApi endpoint path configuration not found for ${endpoint.toString()}`);
    return endpointPath;
  }
}
