import { EntityAdapter } from '@ngrx/entity';

import { MessageableFactory, SignalableFactory } from '../../ngrx';
import { createLoadingStateAdapter } from '../loading-state-adapter';
import { createPaginatedStateFactory } from './paginated-state';
import { createSelectorFactory } from './paginated-state-selectors';

// export function createPaginatedStateAdapter<T extends { id: string | number }, F extends string>(
//   signalableFactory: SignalableFactory<F>,
//   messageableFactory: MessageableFactory<F>,
//   adapter: EntityAdapter<T>,
// );
// export function createPaginatedStateAdapter<T, F extends string>(
//   signalableFactory: SignalableFactory<F>,
//   messageableFactory: MessageableFactory<F>,
//   adapter: EntityAdapter<T>,
//   getId: (entity: T) => string | number,
// );
export function createPaginatedStateAdapter<T, F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>,
  adapter: EntityAdapter<T>,
  getId?: (entity: T) => string | number,
) {
  const loadingStateAdapter = createLoadingStateAdapter<F>(messageableFactory, 'pagination');
  return {
    ...createPaginatedStateFactory<F, T>(
      signalableFactory,
      messageableFactory,
      loadingStateAdapter,
      getId,
    ),
    ...createSelectorFactory(adapter, loadingStateAdapter),
  };
}

export * from './interfaces';
export * from './paginated-state-selectors';
