import { DEFAULT_INITIAL_LOADING_STATE } from './../adapters/loading-state-adapter/loading-state';
import { IPaginationState } from '../interfaces/pagination';

export const DEFAULT_PAGINATION_INITIAL_STATE: IPaginationState = {
  pageSize: null,
  totalCount: null,
  currentPage: null,
  pages: {},
  loadingState: DEFAULT_INITIAL_LOADING_STATE,
  enableFilter: false,
};

export const getPaginationInitialState = () => DEFAULT_PAGINATION_INITIAL_STATE;
