import { IContactFormState } from '@locumsnest/core/src/lib/adapters/contact-form-adapter';

import {
  createContactFormReducer,
  DEFAULT_INITIAL_FORM_STATE,
  DEFAULT_VALIDATORS,
} from './contact-form-state';
import { IContactFormValidator } from './interfaces';

export * from './interfaces';
export const createContactFormAdapter = (formID) => ({
  createContactFormReducer(
    validator: IContactFormValidator = DEFAULT_VALIDATORS,
    initialFormState: IContactFormState = DEFAULT_INITIAL_FORM_STATE
  ) {
    return createContactFormReducer(formID, validator, initialFormState);
  },
});
