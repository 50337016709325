import { at, isNil, pick, pickBy } from 'lodash-es';
import { box, FormState, unbox, Unboxed } from 'ngrx-forms';
import { combineLatest, EMPTY, filter, first, map, mergeMap, Observable, of } from 'rxjs';

import { RouterService } from '@locumsnest/core/src/lib/router/router.service';

import { IGlobalFilterService } from './interfaces';

type UnBoxedForm<T> = {
  [prop in keyof T]: T[prop] | Unboxed<T[prop]>;
};

export abstract class LocalStorageFilterFormService<
  F,
  G,
  P,
  K extends readonly string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  V extends Array<any>,
> {
  protected readonly paramKey = 'hasFilter';
  protected readonly boxedKeys: string[];
  protected abstract globalFilterService: IGlobalFilterService<G>;
  protected abstract readonly initialFormState: F;
  protected abstract readonly namespace: string;
  protected abstract readonly filterKeys: K;
  protected abstract readonly routerService: RouterService;
  protected abstract getFormFilters(...args): Observable<P>;
  protected abstract transformFormFiltersToParams(v: V, ...args): Observable<P>;
  protected abstract getFormState(): Observable<FormState<F>>;

  getInitialFormState(): Observable<F> {
    return this.routerService
      .hasFilters()
      .pipe(first())
      .pipe(mergeMap((hasFilters) => this.synthesizeInitialFormState(hasFilters)));
  }

  getGlobalFilters() {
    return this.globalFilterService.getGlobalFilters();
  }

  getInitialFilterParamsForRoute(...args): Observable<P> {
    return this.getInitialFilterValuesForRoute().pipe(
      mergeMap((v) => this.transformFormFiltersToParams(v, ...args)),
    );
  }

  getFilters(...args) {
    return combineLatest([this.getGlobalFilters(), this.getFormFilters(...args)]).pipe(
      map(([globalFilters, formFilters]) => ({
        ...globalFilters,
        ...formFilters,
      })),
    );
  }

  enableCurrentFiltersForRoute() {
    return this.getFormValue().pipe(
      first(),
      mergeMap((filters: F) => {
        this.enableFiltersForRoute(filters);
        return this.routerService.hasFilters().pipe(
          filter((hasFilters) => hasFilters),
          first(),
          mergeMap(() => EMPTY),
        );
      }),
    );
  }

  protected getDynamicInitialState(): Observable<Partial<F>> {
    return of({});
  }

  protected synthesizeInitialFilterState(fromStorage: boolean) {
    return this.synthesizeInitialFormState(fromStorage).pipe(
      map((initialFormState) => pick(initialFormState, this.filterKeys)),
    );
  }

  private serializeFilterForm(filters: Record<string, unknown>) {
    filters = pickBy(pick(filters, this.filterKeys), (k) => !(isNil(k) || k === ''));
    for (const key in filters) {
      if (filters[key]?.hasOwnProperty('__boxed')) filters[key] = unbox(filters[key]);
    }
    localStorage.setItem(this.namespace, JSON.stringify(filters));
  }

  private deSerializeFilterForm() {
    const boxedFilters: Record<string, unknown> = {};

    const filters: UnBoxedForm<F> = {
      ...this.initialFormState,
      ...pick<F>(JSON.parse(localStorage.getItem(this.namespace)), this.filterKeys),
    };

    for (const key in filters) {
      if (this.boxedKeys?.includes(key)) {
        boxedFilters[key] = box(filters[key]);
      }
    }
    return { ...filters, ...boxedFilters } as F;
  }

  private synthesizeInitialFormState(hasFilters: boolean) {
    return hasFilters
      ? of(this.deSerializeFilterForm())
      : this.getDynamicInitialState().pipe(
          map((dynamicState) => ({
            ...this.initialFormState,
            ...dynamicState,
          })),
        );
  }

  private getInitialFilterValues(fromLocalStorage: boolean): Observable<V> {
    return this.synthesizeInitialFilterState(fromLocalStorage).pipe(
      map(
        (initialFilterState) =>
          at(initialFilterState as Record<string, unknown>, this.filterKeys) as V,
      ),
    );
  }

  private getInitialFilterValuesForRoute(): Observable<V> {
    return this.routerService
      .hasFilters()
      .pipe(first())
      .pipe(mergeMap((hasFilter: boolean) => this.getInitialFilterValues(hasFilter)));
  }

  private enableFiltersForRoute(filters) {
    this.serializeFilterForm(filters);
    this.routerService.enableFilters();
  }

  private getFormValue(): Observable<F> {
    return this.getFormState().pipe(map(({ value }) => pick(value, this.filterKeys) as F));
  }
}
