import { Injectable, OnDestroy } from '@angular/core';
import { Observable, reduce, scan, Subscription } from 'rxjs';

import { HttpApiConfigurationService } from '@locumsnest/core/src/lib/http/http-api-configuration.service';

import { joinPath } from '../helpers/file';
import { buildQueryParams } from '../helpers/util';
import {
  deferredSerializationEndpoints$,
  IDeferredSerializationConfig,
  IndexType,
} from './endpoint.helper';

@Injectable()
export class HttpEndpointService<T> implements OnDestroy {
  private deferredSerializationRegex: RegExp;
  private deferredSerializationRegexStr$: Observable<string>;
  private deferredSerializationRegexSubscription: Subscription;
  private baseUrl: string;
  private version: string;
  private basePath: string;
  constructor(private httpApiConfigurationService: HttpApiConfigurationService<T>) {
    this.deferredSerializationRegexStr$ = deferredSerializationEndpoints$.pipe(
      scan((acc, deferredSerializationCfg) => {
        acc = acc ? acc + '|' : acc;
        return acc + this.buildDeferredSerializationRegex(deferredSerializationCfg);
      }, ''),
    );
    this.deferredSerializationRegexSubscription = this.deferredSerializationRegexStr$.subscribe(
      (v) => {
        this.deferredSerializationRegex = new RegExp(v);
      },
    );
    this.version = this.httpApiConfigurationService.get('version');
    this.baseUrl = this.httpApiConfigurationService.get('baseUrl');
    this.basePath = `${this.baseUrl}${this.version}`;
  }

  public getEndpoint(endpoint: keyof T) {
    const endpointPath = this.getEndpointPath(endpoint);
    return `${this.basePath}${endpointPath}`;
  }

  public getUrl(endpoint: keyof T, queryParams) {
    return this.getEndpoint(endpoint) + buildQueryParams(queryParams);
  }
  public getUrlPath(url: string) {
    return url.replace(this.basePath, '');
  }
  public defersSerialization(endpointUrl: string) {
    if (!this.deferredSerializationRegex) {
      return false;
    }
    return this.deferredSerializationRegex.test(this.getUrlPath(endpointUrl));
  }
  public ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.deferredSerializationRegexSubscription.unsubscribe();
  }
  private buildDeferredSerializationRegex({
    endpoint,
    subResources,
  }: IDeferredSerializationConfig<IndexType>) {
    const endpointPath = this.getEndpointPath(endpoint as keyof T);
    return [endpointPath, ...subResources.map((sr) => joinPath(sr, endpointPath as string))]
      .map((p: string) => `^${joinPath(p, '?[^\\/]*/?')}$`)
      .join('|');
  }

  private getEndpointPath<E extends keyof T>(endpoint: E): T[E] {
    return this.httpApiConfigurationService.getEndpointPath(endpoint);
  }
}
