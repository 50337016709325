import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterStateSerializer } from '@ngrx/router-store';

import {
  HTTP_API_CONFIG,
  HttpApiConfigurationService,
  HttpEndpointService,
} from '@locumsnest/core/src/lib/http';
import { HttpApiDateInterceptor } from '@locumsnest/core/src/lib/http/http-api-date.interceptor';
import { HttpApiJsonInflectionInterceptor } from '@locumsnest/core/src/lib/http/http-api-json-inflection.interceptor';
import { IHttpApiConfiguration } from '@locumsnest/core/src/lib/interfaces';
import { SimpleRouterStateSerializer } from '@locumsnest/core/src/lib/router';
import { WINDOW_PROVIDERS } from '@locumsnest/core/src/lib/services/window.service';
import { ZendeskResTransformInterceptor } from '@locumsnest/zendesk/zendesk-res-transform.interceptor';

import { IMicroAppConfiguration } from './micro-app/interfaces';
import { MicroAppModule } from './micro-app/micro-app.module';
import { MICRO_APP_CONFIGURATION } from './micro-app/opaque-tokens';

@NgModule({
  imports: [CommonModule, MicroAppModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('Locumsnest Core  is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot<T>(
    httpApiConfiguration: IHttpApiConfiguration<T>,
    microAppConfiguration: IMicroAppConfiguration[]
  ): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        HttpApiConfigurationService,
        HttpEndpointService,
        { provide: HTTP_API_CONFIG, useValue: httpApiConfiguration },
        {
          provide: RouterStateSerializer,
          useClass: SimpleRouterStateSerializer,
        },
        WINDOW_PROVIDERS,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpApiJsonInflectionInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpApiDateInterceptor,
          multi: true,
        },
        {
          provide: MICRO_APP_CONFIGURATION,
          useValue: microAppConfiguration,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ZendeskResTransformInterceptor,
          multi: true,
        },
      ],
    };
  }
}
