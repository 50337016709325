import { inject } from '@angular/core';
import { Action } from '@ngrx/store';
import { at, isArray, isEqual, isNil, pick, pickBy } from 'lodash-es';
import { FormState, unbox } from 'ngrx-forms';
import { combineLatest, EMPTY, filter, first, map, Observable, switchMap, tap } from 'rxjs';

import { RouterService } from '@locumsnest/core/src/lib/router/router.service';

import { IGlobalFilterService } from './interfaces';

export type ValuesToString<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : string;
};

export abstract class QueryParamsFilterFormService<
  F,
  G,
  P,
  K extends readonly string[],
  V extends Array<unknown>,
  S extends object & Record<string, any>,
> {
  protected abstract globalFilterService: IGlobalFilterService<G>;
  protected abstract readonly initialFormState: S;
  protected abstract readonly namespace: string;
  protected abstract readonly filterKeys: K;
  protected abstract getFormFilters(...args): Observable<P>;
  protected abstract transformFormFiltersToParams(v: V, ...args): Observable<P>;
  protected abstract getFormState(): Observable<FormState<F>>;
  protected abstract deserializeForBackend(): Observable<Partial<S>>;

  protected readonly routerService = inject(RouterService);

  // TODO types
  protected abstract get serializer();

  enableFiltersForRoute(filters: Partial<S>) {
    const serialized = this.serializeFilterForm(filters);
    this.routerService.addQueryParams(serialized);
  }

  getGlobalFilters() {
    return this.globalFilterService.getGlobalFilters();
  }

  getFilters(...args) {
    return combineLatest([this.getGlobalFilters(), this.getFormFilters(...args)]).pipe(
      map(([globalFilters, formFilters]) => ({
        ...globalFilters,
        ...formFilters,
      })),
    );
  }

  enableCurrentFiltersForRoute(): Observable<Action> {
    return this.getFormValue().pipe(
      first(),
      tap((filters) => this.enableFiltersForRoute(filters)),
      switchMap(() => EMPTY),
    );
  }

  getDeserializedQueryParams() {
    return this.routerService
      .getQueryParams()
      .pipe(map((filters: ValuesToString<S>) => this.serializer.deserialize(filters)));
  }

  getNotNullDeserializedQueryParams() {
    return this.getDeserializedQueryParams().pipe(map((params) => this.pickByNotNull(params)));
  }

  getFilteredQueryParams() {
    return this.routerService
      .getQueryParams()
      .pipe(map((filters: ValuesToString<S>) => this.getFilterKeys(filters)));
  }

  serialize(filters: Partial<S>): ValuesToString<S> {
    return this.serializer.serialize(filters);
  }

  deserialize(filters: ValuesToString<S>): S {
    return this.serializer.deserialize(filters);
  }

  serializeFilterForm(filters: Partial<S>) {
    const initialFormValues = this.getFilterKeys(this.initialFormState);
    return this.serializer.serialize({ ...initialFormValues, ...filters });
  }

  getFormValue(): Observable<S> {
    return this.getFormState().pipe(map(({ value }) => this.getFilterKeys(value as S)));
  }

  getFilterKeys(value: S): S;
  getFilterKeys(value: Partial<S>): Partial<S>;
  getFilterKeys(value: ValuesToString<S>): ValuesToString<S>;
  getFilterKeys(value: S | ValuesToString<S> | Partial<S>): S | ValuesToString<S> | Partial<S> {
    return pick(value, this.filterKeys) as S | ValuesToString<S>;
  }

  getInitialFilterValues(): Observable<P> {
    return this.routerService.getQueryParams().pipe(
      filter((params) => !!Object.values(params).length),
      switchMap(() => this.getDeserializedQueryParams()),
      map((initialFilterState) => at(initialFilterState, this.filterKeys) as V),
      switchMap((res) => this.transformFormFiltersToParams(res)),
    );
  }

  getNonNullFilterKeys(filters: S) {
    const filterKeys = this.getFilterKeys(filters);
    return this.pickByNotNull(filterKeys);
  }

  pickByNotNull(filters: S) {
    return pickBy<S>(filters, (value) => {
      const unBoxedValue = unbox(value);

      if (isArray(unBoxedValue)) {
        return !!unBoxedValue.length;
      }

      return !isNil(unBoxedValue);
    });
  }

  isEqual(filters1: Partial<S>, filters2: Partial<S>) {
    return isEqual(this.serialize(filters1), this.serialize(filters2));
  }
}
